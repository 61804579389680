import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useEffect, useState } from 'react';

import { ResumeLanguagesFormProps } from './ResumeLanguagesForm.types';

import { AddIcon, DeleteIcon, DragIcon } from '../../atoms/Icons';
import { AutocompleteInput } from '../../atoms/Form/AutocompleteInput';
import { PaperCollapse } from '../PaperCollapse';
import { IconButton } from '../../atoms/Icons/Icon';
import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  AddAction,
  AddActionLabel,
  ItemContainer,
  GroupItems,
  HalfItem,
  PaperTitle,
  DraggableContainer,
  Container,
} from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';
import { SelectInput } from '../../atoms/Form/SelectInput/SelectInput';

export const ResumeLanguagesForm = ({ dispatch, resume }: ResumeLanguagesFormProps) => {
  const [keyOpened, setKeyOpened] = useState<string>(resume.languages?.items?.[0]?.key || '');

  const languages = resume?.languages;
  const customItems = languages?.items || [];

  const onDragEnd = (result: any) => {
    dispatch({
      type: 'REORDER_ADDITIONAL_SECTION_NODE',
      payload: { section: 'languages', sourceIndex: result.source.index, destinationIndex: result.destination.index },
    });
  };

  return (
    <ErrorBoundary message="Molecule\ResumeLanguagesForm">
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {customItems.map((item, index) => {
                  if (!item?.key) return;
                  return (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided, snap) => {
                        const isDragging = snap.isDragging;
                        return (
                          <DraggableContainer
                            $isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  dispatch({
                                    type: 'REMOVE_ADDITIONAL_SECTION_NODE',
                                    payload: { key: 'languages', index },
                                  });
                                }}
                              />
                            </IconButton>
                            <Item
                              item={item}
                              index={index}
                              dispatch={dispatch}
                              keyOpened={keyOpened}
                              setKeyOpened={setKeyOpened}
                              isDragging={isDragging}
                            ></Item>
                            <IconButton {...provided.dragHandleProps}>
                              <DragIcon />
                            </IconButton>
                          </DraggableContainer>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Spacer y={16} />

        <AddAction
          onClick={() => {
            dispatch({
              type: 'ADD_ADDITIONAL_SECTION_NODE',
              payload: { key: `languages` },
            });
          }}
        >
          <AddIcon size={2} />
          <AddActionLabel>Add one more language</AddActionLabel>
        </AddAction>

        <Spacer y={32} />
      </Container>
    </ErrorBoundary>
  );
};

const Item = ({ item, index, dispatch, keyOpened, setKeyOpened, isDragging }: any) => {
  const { key, language = '', proficiency = '' } = item;
  const title = language ? language : 'Language';
  const MainTitle = <PaperTitle>{title}</PaperTitle>;
  const isOpen = keyOpened === key && !isDragging;

  return (
    <ItemContainer $isDragging={isDragging}>
      <PaperCollapse
        onChange={() => {
          setKeyOpened(key);
        }}
        key={`key-${key}-${isOpen}`}
        title={MainTitle}
        open={isOpen}
      >
        {isOpen ? (
          <GroupItems>
            <HalfItem>
              <AutocompleteInput
                options={commonLanguages}
                name="language"
                label="Language"
                defaultValue={language || ''}
                placeholder='e.g. "English", "Spanish", "French"'
                onChange={(value) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `languages.items[${index}].language`, value: value },
                  });
                }}
                // onSelect={(e) => {
                //   dispatch({
                //     type: 'CHANGE_FORM_VALUE',
                //     payload: { key: `languages.items[${index}].language`, value: e?.target?.value },
                //   });
                // }}
                // onBlur={(e) => {
                //   dispatch({
                //     type: 'CHANGE_FORM_VALUE',
                //     payload: { key: `languages.items[${index}].language`, value: e?.target?.value },
                //   });
                // }}
                value={language}
                noMargin={true}
              ></AutocompleteInput>
            </HalfItem>
            <HalfItem>
              <SelectInput
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `languages.items.[${index}].proficiency`, value: e?.target?.value },
                  });
                }}
                name="proficiency"
                noMargin={true}
                label="Proficiency"
                value={proficiency}
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Novice (A1/A2)</option>
                <option value={2}>Intermediate (B1/B2)</option>
                <option value={3}>Advanced (C1/C2)</option>
                <option value={4}>Fluent</option>
                <option value={5}>Native</option>
              </SelectInput>
            </HalfItem>
          </GroupItems>
        ) : null}
      </PaperCollapse>
    </ItemContainer>
  );
};

const commonLanguages = [
  'Spanish',
  'English',
  'Hindi',
  'Mandarin Chinese',
  'Arabic',
  'Portuguese',
  'Bengali',
  'Russian',
  'Urdu',
  'Indonesian',
  'German',
  'Japanese',
  'Swahili',
  'Marathi',
  'Telugu',
  'Turkish',
  'Yoruba',
  'Tamil',
  'Punjabi',
  'Italian',
  'Vietnamese',
  'Gujarati',
  'Korean',
  'French',
  'Filipino (Tagalog)',
  'Burmese',
  'Thai',
  'Polish',
  'Ukrainian',
  'Malay',
];
