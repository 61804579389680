import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useState } from 'react';

import { ResumeLinksFormProps } from './ResumeLinksForm.types';

import {
  AddIcon,
  DeleteIcon,
  DragIcon,
  FacebookIcon,
  GithubIcon,
  InstagramIcon,
  LinkIcon,
  LinkedinIcon,
  TwitterIcon,
} from '../../atoms/Icons';
import { TextInput } from '../../atoms/Form/TextInput';
import { PaperCollapse } from '../PaperCollapse';
import { IconButton } from '../../atoms/Icons/Icon';
import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  AddAction,
  AddActionLabel,
  ItemContainer,
  GroupItems,
  HalfItem,
  PaperTitle,
  DraggableContainer,
  Container,
} from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';

export const ResumeLinksForm = ({ dispatch, resume }: ResumeLinksFormProps) => {
  const [keyOpened, setKeyOpened] = useState<string>(resume.links?.items?.[0]?.key || '');

  const links = resume?.links;
  const customItems = links?.items || [];

  const onDragEnd = (result: any) => {
    dispatch({
      type: 'REORDER_ADDITIONAL_SECTION_NODE',
      payload: { section: 'links', sourceIndex: result.source.index, destinationIndex: result.destination.index },
    });
  };

  return (
    <ErrorBoundary message="Molecule\ResumeLinksForm">
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column">
            {(provided, snap) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {customItems.map((item, index) => {
                  if (!item?.key) return;
                  return (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided, snap) => {
                        const isDragging = snap.isDragging;
                        return (
                          <DraggableContainer
                            $isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  dispatch({
                                    type: 'REMOVE_ADDITIONAL_SECTION_NODE',
                                    payload: { key: 'links', index },
                                  });
                                }}
                              />
                            </IconButton>
                            <Item
                              item={item}
                              index={index}
                              dispatch={dispatch}
                              keyOpened={keyOpened}
                              setKeyOpened={setKeyOpened}
                              isDragging={isDragging}
                            ></Item>
                            <IconButton {...provided.dragHandleProps}>
                              <DragIcon />
                            </IconButton>
                          </DraggableContainer>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Spacer y={16} />

        <AddAction
          onClick={() => {
            dispatch({
              type: 'ADD_ADDITIONAL_SECTION_NODE',
              payload: { key: `links` },
            });
          }}
        >
          <AddIcon size={2} />
          <AddActionLabel>Add one more social link</AddActionLabel>
        </AddAction>

        <Spacer y={32} />
      </Container>
    </ErrorBoundary>
  );
};

const Item = ({ item, index, dispatch, keyOpened, setKeyOpened, isDragging }: any) => {
  const { key, type = '', text = '', url = '' } = item;
  const title = text ? text : 'Url';
  const MainTitle = (
    <PaperTitle>
      {getSocialMediaIcon(type)}
      {title}
    </PaperTitle>
  );
  const isOpen = keyOpened === key && !isDragging;

  return (
    <ItemContainer $isDragging={isDragging}>
      <PaperCollapse
        onChange={() => {
          setKeyOpened(key);
        }}
        key={`key-${key}-${isOpen}`}
        title={MainTitle}
        open={isOpen}
      >
        <GroupItems>
          <HalfItem>
            <TextInput
              name="text"
              label="Link text"
              onChange={(e) => {
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: `links.items[${index}].text`, value: e?.target?.value },
                });
              }}
              value={text}
              noMargin={true}
            />
          </HalfItem>
          <HalfItem>
            <TextInput
              name="url"
              label="Url"
              type="url"
              onChange={(e) => {
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: `links.items[${index}].type`, value: getSocialMediaType(e?.target?.value) },
                });
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: `links.items[${index}].url`, value: e?.target?.value },
                });
              }}
              value={url}
              noMargin={true}
            />
          </HalfItem>
        </GroupItems>
      </PaperCollapse>
    </ItemContainer>
  );
};

function getSocialMediaType(url: string): SocialMediaType {
  const lowercasedUrl = url.toLowerCase();

  if (lowercasedUrl.includes('linkedin.com')) {
    return 'linkedin';
  } else if (lowercasedUrl.includes('facebook.com')) {
    return 'facebook';
  } else if (lowercasedUrl.includes('twitter.com')) {
    return 'twitter';
  } else if (lowercasedUrl.includes('instagram.com')) {
    return 'instagram';
  } else if (lowercasedUrl.includes('github.com')) {
    return 'github';
  }

  return 'unknown';
}

function getSocialMediaIcon(type: SocialMediaType) {
  switch (type) {
    case 'linkedin':
      return <LinkedinIcon size={1} />;
    case 'facebook':
      return <FacebookIcon size={1} />;
    case 'twitter':
      return <TwitterIcon size={1} />;
    case 'instagram':
      return <InstagramIcon />;
    case 'github':
      return <GithubIcon />;
    default:
      return <LinkIcon />;
  }
}

type SocialMediaType = 'linkedin' | 'facebook' | 'twitter' | 'instagram' | 'github' | 'unknown';
