import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { useResumeContext } from '../Context';
import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';

import { Colors } from '../../../styles/colors';
import { Urls } from '../../urls';
import { formatUrl } from '../../../utils/url';

import { H3 } from '../../../components/atoms/Typography/Headings';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { Wrapper, WrapperLeft, WrapperRight } from './Common.styles';
import { ResumeAdditionalSections } from '../../../components/molecules/ResumeAdditionalSections';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { ResumePreview } from '../../../components/molecules/ResumePreview';
import { WrapperButtons, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { NavigateBeforeIcon, NavigateNextIcon } from '../../../components/atoms/Icons';
import { Form } from '../../../components/atoms/Form/Form';
import { ResumeReorderSections } from '../../../components/molecules/ResumeReoderSections';
import { FunctionalIFrameComponent } from '../../../components/atoms/FunctionalIFrameComponent';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { ResumeActions } from '../../../components/molecules/ResumeActions';
import { Board, Job } from '../../../generated/types';

export const AdditionalSection: React.FC<AdditionalSectionProps> = ({
  handleSave,
  handleDownload,
  isOpen,
  board,
  job,
}) => {
  const { query: { userUuid, boardUuid = '', jobUuid = '', resumeUuid = '' } = {} } = useRouter();
  const { state, dispatch, title } = useResumeContext();
  const user = useUserContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { additionalSections, isLoading, isSaving } = state;

  if (!userUuid) {
    return null;
  }

  if (!boardUuid) {
    return null;
  }

  if (!resumeUuid) {
    return null;
  }

  const selectedAdditionalSections = additionalSections.map((additionalSection) => {
    const { section } = additionalSection;
    return section;
  });

  const child = (
    <Wrapper>
      <WrapperLeft>
        <Spacer y={22} />
        <Breadcrumbs>
          <Breadcrumb>
            <Link to={`/${Urls.Boards}`}>All Boards</Link>
          </Breadcrumb>
          <Breadcrumb>
            <Link
              to={formatUrl(`/${Urls.Boards}/${Urls.BoardView}#resumes`, {
                userUuid,
                boardUuid,
              })}
            >
              {board ? board.title : 'Current Board'}
            </Link>
          </Breadcrumb>
          {jobUuid ? (
            <Breadcrumb>
              <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}#resumes`}>
                {job ? job.jobTitle : 'Current Job'}
              </Link>
            </Breadcrumb>
          ) : null}
          <Breadcrumb>Resume</Breadcrumb>
        </Breadcrumbs>
        <H3>Additional Sections</H3>
        <InfoAlert>
          <p>
            You should only add resume categories if they are relevant and you can list a few things in each section.
            Pick the most impactful categories first.
          </p>
        </InfoAlert>
        {isLoading ? (
          <SkeletonBlock style={{ height: '260px', marginBottom: '32px' }} />
        ) : (
          <Form onSubmit={() => {}}>
            <ResumeAdditionalSections
              selectedAdditionalSections={selectedAdditionalSections}
              onActionClick={(additionalSection) => {
                dispatch({
                  type: 'ADD_ADDITIONAL_SECTION',
                  payload: additionalSection,
                });
              }}
            />
            <Spacer y={32} />
            <ResumeReorderSections dispatch={dispatch} state={state}></ResumeReorderSections>
            <Spacer y={32} />
            <WrapperButtons>
              <PrimaryButton
                onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.preventDefault();
                  await handleSave();
                }}
                inline
                busy={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </PrimaryButton>

              <WrapperButtons>
                <PrimaryButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    dispatch({
                      type: 'CHANGE_STEP',
                      payload: `${3 + additionalSections.length}`,
                    });
                  }}
                  inline
                  iconLeft={<NavigateBeforeIcon color={Colors.White} size={2} />}
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    dispatch({
                      type: 'CHANGE_STEP',
                      payload: `${5 + additionalSections.length}`,
                    });
                  }}
                  inline
                  iconRight={<NavigateNextIcon color={Colors.White} size={2} />}
                >
                  Next
                </PrimaryButton>
              </WrapperButtons>
            </WrapperButtons>
          </Form>
        )}
      </WrapperLeft>
      <WrapperRight>
        {isLoading ? (
          <SkeletonBlock style={{ height: '660px', marginBottom: '32px' }} />
        ) : (
          <>
            <ResumeActions
              user={user}
              userUuid={userUuid}
              boardUuid={boardUuid}
              jobUuid={jobUuid}
              resumeUuid={resumeUuid}
              handleDownload={handleDownload}
            />
            <FunctionalIFrameComponent title="resume">
              <ResumePreview state={state} isOpen={isOpen} />
            </FunctionalIFrameComponent>
          </>
        )}
      </WrapperRight>
    </Wrapper>
  );

  return (
    <>
      <Helmet title={`${title} - Additional Sections`} />
      {child}
    </>
  );
};

type AdditionalSectionProps = {
  isOpen: boolean;
  job?: Job | null;
  board?: Board | null;
  handleSave: () => Promise<void>;
  handleDownload: () => Promise<void>;
};
