import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';

export const Container = styled.div``;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Action = styled.div`
  padding: 16px 16px;
  border: 1px solid ${Colors.GreyLightest};
  background-color: ${Colors.White};
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ActionLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const DraggableContainer = styled.section<{ $isDragging: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 4px;
  ${(props) =>
    props.$isDragging &&
    css`
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    `}
`;
