import { Lightbulb } from '@styled-icons/fa-regular/';
import { Icon, IconProps } from './Icon';

export function LightIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Lightbulb />
    </Icon>
  );
}
