import { useCallback, useReducer } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import unset from 'lodash/unset';
import set from 'lodash/set';

import {
  DesignColorPalette as DesignColorPaletteEnum,
  DesignFontFamily as DesignFontFamilyEnum,
  DesignDensity,
  DesignTemplateName,
} from '../../../generated/types';
import generateID from '../../../helper/id';
import { Descendant } from 'slate';

export const initialState: ResumeStoreState = {
  isLoading: true,
  isDirty: 0,
  isSaving: false,
  currentstep: '1',
  additionalSections: [],
  design: {
    colorPalette: DesignColorPaletteEnum.Default,
    fontFamily: DesignFontFamilyEnum.Palatino,
    density: DesignDensity.Normal,
    templateName: DesignTemplateName.Aries,
    hasLastModifiedDate: false,
    hasParagraphIndendation: false,
    paragraphTextAlignment: 'justify',
  },
};

export function reducer(state: ResumeStoreState, { type, payload }: Action): ResumeStoreState {
  const { resume, design, currentstep } = state;

  switch (type) {
    case 'SET_IS_SAVING':
      return {
        ...state,
        isSaving: payload,
      };

    case 'SET_IS_DIRTY':
      return {
        ...state,
        isDirty: payload,
      };

    case 'INITIALIZE_STATE': {
      try {
        const updatedState = JSON.parse(JSON.stringify(payload));
        return {
          ...state,
          ...updatedState,
          isLoading: false,
          isDirty: 0,
          currentstep,
          isSaving: false,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'CHANGE_DESIGN_VALUE': {
      try {
        const { key, value } = payload;
        const updatedDesign = {
          ...design,
        };
        set(updatedDesign, key, value);
        return {
          ...state,
          design: updatedDesign,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'CHANGE_FORM_VALUE': {
      try {
        const { key, value } = payload;
        const updatedResume = {
          ...resume,
        };
        set(updatedResume, key, value);
        return {
          ...state,
          resume: updatedResume,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'DELETE_FORM_VALUE': {
      try {
        const { key } = payload;
        const updatedResume = {
          ...resume,
        };
        unset(updatedResume, key);
        return {
          ...state,
          resume: updatedResume,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'CHANGE_STEP': {
      try {
        return {
          ...state,
          currentstep: payload,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'ADD_ADDITIONAL_SECTION': {
      try {
        const { key, section, title } = payload;
        return {
          ...state,
          resume: {
            ...resume,
            ...(section === 'pagebreak'
              ? {
                  [key]: {
                    title,
                    section,
                    items: [{ key }],
                  },
                }
              : {}),
            ...(section === 'skills'
              ? {
                  [section]: {
                    title,
                    section,
                    items: [{ key, name: '', level: '' }],
                  },
                }
              : {}),
            ...(section === 'certifications'
              ? {
                  [section]: {
                    title,
                    section,
                    items: [{ key, title: '', issuer: '', date: '', url: '' }],
                  },
                }
              : {}),
            ...(section === 'hobbies'
              ? {
                  [section]: {
                    title,
                    section,
                    content: undefined,
                  },
                }
              : {}),
            ...(section === 'references'
              ? {
                  [section]: {
                    title,
                    section,
                    onDemand: false,
                    items: [{ key, name: '', phone: '', email: '', company: '' }],
                  },
                }
              : {}),
            ...(section === 'custom'
              ? {
                  [key]: {
                    title,
                    section,
                    items: [{ key, header: '', subheader: '', content: undefined }],
                  },
                }
              : {}),
            ...(section === 'languages'
              ? {
                  [section]: {
                    title,
                    section,
                    items: [{ key, language: '', proficiency: '' }],
                  },
                }
              : {}),
            ...(section === 'links'
              ? {
                  [section]: {
                    title,
                    section,
                    items: [{ key, name: '', url: '', type: '' }],
                  },
                }
              : {}),
            ...(section === 'internships'
              ? {
                  [section]: {
                    title,
                    section,
                    items: [
                      {
                        key,
                        company: '',
                        position: '',
                        location: '',
                        startDate: '',
                        endDate: '',
                        highlights: undefined,
                      },
                    ],
                  },
                }
              : {}),
            ...(section === 'employments'
              ? {
                  [section]: {
                    title,
                    section,
                    items: [
                      {
                        key,
                        company: '',
                        position: '',
                        location: '',
                        startDate: '',
                        endDate: '',
                        highlights: undefined,
                      },
                    ],
                  },
                }
              : {}),
            ...(section === 'educations'
              ? {
                  [section]: {
                    title,
                    section,
                    items: [
                      {
                        key,
                        institution: '',
                        studyType: '',
                        location: '',
                        startDate: '',
                        endDate: '',
                        area: '',
                        score: '',
                      },
                    ],
                  },
                }
              : {}),
            ...(section === 'projects'
              ? {
                  [section]: {
                    title,
                    section,
                    items: [
                      {
                        key,
                        name: '',
                        date: '',
                        url: '',
                        keywords: [],
                        highlights: undefined,
                      },
                    ],
                  },
                }
              : {}),
          },
          additionalSections: [...state.additionalSections, { key, section, title }],
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'ADD_ADDITIONAL_SECTION_NODE': {
      try {
        const { key } = payload;
        const id = generateID();
        const updatedState = JSON.parse(JSON.stringify(state));
        updatedState.resume[key].items.push({
          key: id,
        });
        return { ...updatedState, isDirty: state.isDirty + 1 };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'CHANGE_ADDITIONAL_SECTION_TITLE': {
      try {
        const { key, title } = payload;
        const updatedState = JSON.parse(JSON.stringify(state));
        set(updatedState, `resume.${key}.title`, title);
        return { ...updatedState, isDirty: state.isDirty + 1 };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'REMOVE_ADDITIONAL_SECTION': {
      try {
        const { key } = payload;
        const udpatedState = JSON.parse(JSON.stringify(state));
        delete udpatedState.resume[key];
        return {
          ...udpatedState,
          additionalSections: state.additionalSections.filter((item) => item.key !== key),
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'REMOVE_ADDITIONAL_SECTION_NODE': {
      try {
        const { key, index } = payload;
        const updatedState = JSON.parse(JSON.stringify(state));
        updatedState.resume[key].items.splice(index, 1);
        return { ...updatedState, isDirty: state.isDirty + 1 };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'REORDER_ADDITIONAL_SECTION': {
      try {
        const { sourceIndex, destinationIndex } = payload;
        const udpatedState = JSON.parse(JSON.stringify(state));
        const { additionalSections } = udpatedState;
        const [removed] = additionalSections.splice(sourceIndex, 1);
        additionalSections.splice(destinationIndex, 0, removed);
        return {
          ...udpatedState,
          additionalSections,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'REORDER_ADDITIONAL_SECTION_NODE': {
      try {
        const { section, sourceIndex, destinationIndex } = payload;
        const udpatedState = JSON.parse(JSON.stringify(state));
        const items = udpatedState.resume[section].items;
        const [removed] = items.splice(sourceIndex, 1);
        items.splice(destinationIndex, 0, removed);
        udpatedState.resume[section].items = items;
        return { ...udpatedState, isDirty: state.isDirty + 1 };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    default:
      return state;
  }
}

export const usePersistReducer = (LOCAL_STORAGE_KEY: string, INITIAL_STATE: ResumeStoreState) => {
  // grab saved value from `localStorage` and
  // a function to update it. if
  // no value is retrieved, use `INITIAL_STATE`
  const [savedState, saveState] = useLocalStorage(LOCAL_STORAGE_KEY, INITIAL_STATE);

  // wrap `reducer` with a memoized function that
  // syncs the `newState` to `localStorage` before
  // returning `newState`. memoizing is important!
  const reducerLocalStorage = useCallback(
    (state: ResumeStoreState, action: Action) => {
      const newState = reducer(state, action);

      saveState(newState);

      return newState;
    },
    [saveState]
  );

  // use wrapped reducer and the saved value from
  // `localStorage` as params to `useReducer`.
  // this will return `[state, dispatch]`
  return useReducer(reducerLocalStorage, INITIAL_STATE);
};

export interface ResumeStoreState {
  isLoading: boolean;
  isDirty: number;
  isSaving: boolean;
  currentstep: string;
  additionalSections: { key: string; section: string; title: string }[];
  resume?: ResumeStore;
  design: DesignStore;
}

export interface ResumeEmploymentStore {
  key: string;
  company: string;
  position: string;
  location: string;
  startDate: string;
  endDate: string;
  highlights: Descendant[] | string[];
}

export interface ResumeInternshipStore {
  key: string;
  company: string;
  position: string;
  location: string;
  startDate: string;
  endDate: string;
  highlights: Descendant[] | string[];
}

export interface ResumeEducationStore {
  key: string;
  institution: string;
  location: string;
  studyType: string;
  area: string;
  score: string;
  startDate: string;
  endDate: string;
  highlights: Descendant[];
}

export interface ResumeCertificationStore {
  key: string;
  title: string;
  issuer: string;
  date: string;
  url: string;
}

export interface ResumeLanguageStore {
  key: string;
  language: string;
  proficiency: number;
}

export interface ResumeProjectStore {
  key: string;
  name?: string;
  date?: string;
  url?: string;
  keywords?: string[];
  highlights?: Descendant[];
}

export interface ResumeHobbyStore {
  title: string;
  content?: Descendant[];
}

export interface ResumeSkillStore {
  key: string;
  name?: string;
  level?: number;
}

export interface ResumeReferenceStore {
  key: string;
  name?: string;
  email?: string;
  company?: string;
  phone?: string;
  linkedIn?: string;
}

export interface ResumeLinkStore {
  key: string;
  type?: string;
  text?: string;
  url?: string;
}

export interface ResumeCustomStore {
  header?: string;
  subheader?: string;
  highlights?: Descendant[];
}

export interface ResumeBasicStore {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  nationality?: string;
  drivingLicense?: string;
  dateOfBirth?: string;
  placeOfBirth?: string;
  isOpen?: boolean;
  availability?: string;
  workRightUK?: boolean;
  workRightEU?: boolean;
}

export interface ResumeStore {
  basics?: ResumeBasicStore;
  contacts?: {
    email: string;
    phone: string;
    country: string;
    city: string;
    address: string;
    postCode: string;
  };
  summary?: Descendant[];
  employments?: {
    title: string;
    items: ResumeEmploymentStore[];
  };
  internships?: {
    title: string;
    items: ResumeInternshipStore[];
  };
  educations?: {
    title: string;
    items: ResumeEducationStore[];
  };
  links?: {
    title: string;
    items: ResumeLinkStore[];
  };
  languages?: {
    title: string;
    items: ResumeLanguageStore[];
  };
  skills?: {
    title: string;
    items: ResumeSkillStore[];
  };
  projects?: {
    title: string;
    items: ResumeProjectStore[];
  };
  references?: {
    title: string;
    onDemand: boolean;
    items: ResumeReferenceStore[];
  };
  custom?: {
    title?: string;
    items: ResumeCustomStore[];
  };
  hobbies?: ResumeHobbyStore;
  certifications?: {
    title: string;
    items: ResumeCertificationStore[];
  };
}

export interface DesignStore {
  colorPalette: DesignColorPaletteEnum;
  fontFamily: DesignFontFamilyEnum;
  density: DesignDensity;
  templateName: DesignTemplateName;
  hasLastModifiedDate?: boolean;
  hasParagraphIndendation?: boolean;
  paragraphTextAlignment?: 'left' | 'justify' | 'right';
}

interface Action {
  type: ActionType;
  payload: any;
}

export type ActionType =
  | 'SET_IS_SAVING'
  | 'SET_IS_DIRTY'
  | 'REORDER_ADDITIONAL_SECTION_NODE'
  | 'REMOVE_ADDITIONAL_SECTION_NODE'
  | 'CHANGE_ADDITIONAL_SECTION_TITLE'
  | 'REORDER_ADDITIONAL_SECTION'
  | 'INITIALIZE_STATE'
  | 'CHANGE_DESIGN_VALUE'
  | 'DELETE_FORM_VALUE'
  | 'CHANGE_FORM_VALUE'
  | 'CHANGE_STEP'
  | 'ADD_ADDITIONAL_SECTION'
  | 'ADD_ADDITIONAL_SECTION_NODE'
  | 'REMOVE_ADDITIONAL_SECTION';
