import { toast } from 'react-toastify';
import { Descendant } from 'slate';

import { ResumeHobbiesFormProps } from './ResumeHobbiesForm.types';

import { SlateEditor } from '../SlateEditor';
import { WritingStyle } from '../../../generated/types';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { GroupItems, Container, FullItem, SlateEditorWrapper } from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';

export const ResumeHobbiesForm = ({ dispatch, resume, handleImproveResume, handleFixTypo }: ResumeHobbiesFormProps) => {
  const { hobbies = {} } = resume;
  const {
    content = [
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
    ],
  } = hobbies;

  async function onImproveDescription(nodes: Descendant[], writingStyle: WritingStyle) {
    if (!handleImproveResume) return [];

    try {
      let count = 1;
      switch (writingStyle) {
        case WritingStyle.Expand:
          count = 1;
          break;
        case WritingStyle.Shorten:
          count = 1;
          break;
        case WritingStyle.Rewrite:
          count = 2;
          break;
        default:
          count = 1;
          break;
      }
      const result = await handleImproveResume('hobbies', nodes, writingStyle, count);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return [];
    }
  }

  async function onFixTypo(selection: string) {
    if (!handleFixTypo) return;
    if (!selection) return;
    if (selection.length < 50) {
      toast.error('No selection. Please select at least 50 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }
    if (selection.length > 3000) {
      toast.error('No selection. Please select no more than 3000 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }

    try {
      const result = await handleFixTypo(selection);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return '';
    }
  }

  return (
    <ErrorBoundary message="Molecule\ResumeHobbiesForm">
      <Container>
        <GroupItems>
          <FullItem>
            <SlateEditorWrapper>
              <SlateEditor
                onImproveDescription={onImproveDescription}
                onFixTypo={onFixTypo}
                onChange={(value) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `hobbies.content`, value },
                  });
                }}
                style={{ minHeight: '200px', outline: 'none', border: 'none' }}
                initialValue={content}
              />
            </SlateEditorWrapper>
          </FullItem>
        </GroupItems>
        <Spacer y={32} />
      </Container>
    </ErrorBoundary>
  );
};
