import { Tools } from '@styled-icons/remix-line';
import { Tools as ToolsFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function SkillIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Tools />
    </Icon>
  );
}

export function SkillFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <ToolsFill />
    </Icon>
  );
}
