import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Descendant } from 'slate';

import { useResumeContext } from '../Context';
import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';

import { formatUrl } from '../../../utils/url';
import { Board, Job, WritingStyle } from '../../../generated/types';
import { Colors } from '../../../styles/colors';
import { Urls } from '../../urls';

import { Form } from '../../../components/atoms/Form/Form';
import { H3 } from '../../../components/atoms/Typography/Headings';
import { WrapperButtons, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { NavigateBeforeIcon, NavigateNextIcon } from '../../../components/atoms/Icons';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { SlateEditorWrapper, Wrapper, WrapperLeft, WrapperRight } from './Common.styles';
import { ResumePreview } from '../../../components/molecules/ResumePreview';
import { SlateEditor } from '../../../components/molecules/SlateEditor';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { FunctionalIFrameComponent } from '../../../components/atoms/FunctionalIFrameComponent';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { Tips } from '../../../components/atoms/Tips';
import { ResumeActions } from '../../../components/molecules/ResumeActions';

export const ProfessionalSummary: React.FC<ProfessionalSummaryProps> = ({
  handleSave,
  handleDownload,
  handleImproveResume,
  handleFixTypo,
  isOpen,
  board,
  job,
}) => {
  const { query: { userUuid, boardUuid = '', jobUuid = '', resumeUuid = '' } = {} } = useRouter();
  const { state, dispatch, title } = useResumeContext();
  const user = useUserContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function onImproveDescription(nodes: Descendant[], writingStyle: WritingStyle): Promise<String[]> {
    if (!handleImproveResume) return [];

    try {
      let count = 1;
      switch (writingStyle) {
        case WritingStyle.Expand:
          count = 1;
          break;
        case WritingStyle.Shorten:
          count = 1;
          break;
        case WritingStyle.Rewrite:
          count = 2;
          break;
        case WritingStyle.Spellcheck:
          count = 1;
          break;
        default:
          count = 1;
          break;
      }
      const result = await handleImproveResume('summary', nodes, writingStyle, count);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return [];
    }
  }

  async function onFixTypo(selection: string): Promise<string> {
    if (!handleFixTypo) return '';
    if (!selection) return '';
    if (selection.length < 50) {
      toast.error('No selection. Please select at least 50 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return '';
    }
    if (selection.length > 3000) {
      toast.error('No selection. Please select no more than 3000 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return '';
    }

    try {
      const result = await handleFixTypo(selection);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return '';
    }
  }

  const { resume = {}, isLoading, isSaving } = state;
  const { summary } = resume;

  if (!userUuid) {
    return null;
  }

  if (!boardUuid) {
    return null;
  }

  if (!resumeUuid) {
    return null;
  }

  const child = (
    <Wrapper>
      <WrapperLeft>
        <Spacer y={22} />
        <Breadcrumbs>
          <Breadcrumb>
            <Link to={`/${Urls.Boards}`}>All Boards</Link>
          </Breadcrumb>
          <Breadcrumb>
            <Link
              to={formatUrl(`/${Urls.Boards}/${Urls.BoardView}#resumes`, {
                userUuid,
                boardUuid,
              })}
            >
              {board ? board.title : 'Current Board'}
            </Link>
          </Breadcrumb>
          {jobUuid ? (
            <Breadcrumb>
              <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}#resumes`}>
                {job ? job.jobTitle : 'Current Job'}
              </Link>
            </Breadcrumb>
          ) : null}
          <Breadcrumb>Resume</Breadcrumb>
        </Breadcrumbs>
        <H3>Professional Summary</H3>
        <InfoAlert>
          Compose 2 to 4 compelling sentences to grab the reader's attention, emphasizing your key role, extensive
          experience, and showcasing remarkable achievements, outstanding qualities, and formidable skills.
        </InfoAlert>

        <Tips
          tips={[
            "Tailor your summary to the specific job you're applying for, emphasizing relevant skills and experiences.",
            'Highlight key accomplishments or results that demonstrate your impact in previous roles.',
            'Showcase your unique value proposition and what sets you apart from other candidates.',
            'Keep it concise and focused, avoiding unnecessary jargon or filler words.',
            'Consider including keywords from the job description to improve visibility and alignment with applicant tracking systems.',
            'Use action verbs to describe your achievements and responsibilities, such as "developed," "implemented," or "managed."',
            'Proofread carefully to ensure clarity, accuracy, and grammatical correctness.',
          ]}
        />
        <Form onSubmit={() => {}}>
          {isLoading ? (
            <SkeletonBlock style={{ height: '260px', marginBottom: '32px' }} />
          ) : (
            <SlateEditorWrapper>
              <SlateEditor
                onImproveDescription={onImproveDescription}
                onFixTypo={onFixTypo}
                onChange={(value) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'summary', value },
                  });
                }}
                style={{ minHeight: '200px', outline: 'none', border: 'none' }}
                initialValue={
                  summary
                    ? summary
                    : [
                        {
                          type: 'paragraph',
                          children: [
                            {
                              text: '',
                            },
                          ],
                        } as Descendant,
                      ]
                }
              />
            </SlateEditorWrapper>
          )}

          <Spacer y={16} />

          <WrapperButtons>
            <PrimaryButton
              onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                await handleSave();
              }}
              inline
              busy={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </PrimaryButton>

            <WrapperButtons>
              <PrimaryButton
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.preventDefault();
                  dispatch({
                    type: 'CHANGE_STEP',
                    payload: '2',
                  });
                }}
                inline
                iconLeft={<NavigateBeforeIcon color={Colors.White} size={2} />}
              >
                Back
              </PrimaryButton>
              <PrimaryButton
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.preventDefault();
                  dispatch({
                    type: 'CHANGE_STEP',
                    payload: '4',
                  });
                }}
                inline
                iconRight={<NavigateNextIcon color={Colors.White} size={2} />}
              >
                Next
              </PrimaryButton>
            </WrapperButtons>
          </WrapperButtons>
        </Form>
      </WrapperLeft>
      <WrapperRight>
        {isLoading ? (
          <SkeletonBlock style={{ height: '660px', marginBottom: '32px' }} />
        ) : (
          <>
            <ResumeActions
              user={user}
              userUuid={userUuid}
              boardUuid={boardUuid}
              jobUuid={jobUuid}
              resumeUuid={resumeUuid}
              handleDownload={handleDownload}
            />
            <FunctionalIFrameComponent title="resume">
              <ResumePreview state={state} isOpen={isOpen} />
            </FunctionalIFrameComponent>
          </>
        )}
      </WrapperRight>
    </Wrapper>
  );

  return (
    <>
      <Helmet title={`${title} - Professional Summary`} />
      {child}
    </>
  );
};

type ProfessionalSummaryProps = {
  handleSave: () => Promise<void>;
  handleDownload: () => Promise<void>;
  handleImproveResume: (
    field: string,
    nodes: Descendant[],
    writingStyle: WritingStyle,
    count: number
  ) => Promise<string[]>;
  handleFixTypo: (selection: string) => Promise<string>;
  isOpen: boolean;
  job?: Job | null;
  board?: Board | null;
};
