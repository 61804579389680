import React, { forwardRef, memo, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextFieldProps } from '@mui/material';
import styled from 'styled-components';

import { Colors } from '../../../../styles/colors';
import { PrimaryButton } from '../../Button';
import { Spacer } from '../../Spacer/Spacer';
import { ChevronLeftIcon, ChevronRightIcon } from '../../Icons';
import { IconButton } from '../../Icons/Icon';
import { ErrorBoundary } from '../../../molecules/ErrorBoundary';

export const DayMonthTimePicker = memo(({ name, label, value, onChange, hasPresent = false }: TextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, [value]);

  let selectedDate = new Date();
  try {
    if (value === 'Present') {
      selectedDate = new Date();
    } else {
      if (typeof value === 'number' && `${value}`.length === 4) {
        selectedDate = new Date(`January 01, ${value}`);
      } else {
        if (value.includes(' ')) {
          const [month, year] = value.split(' ');
          selectedDate = new Date(`${month} 01, ${year}`);
        }
      }
    }
    // check if selectedDate is invalid
    if (selectedDate.toString() === 'Invalid Date') {
      selectedDate = new Date();
    }
  } catch (error) {}

  const CalendarInput = forwardRef((props: any, _ref): any => {
    const { onClick } = props;

    return (
      <CalendarInputWrapper>
        <label>{label}</label>
        <TextFieldExtended
          ref={inputRef}
          onClick={onClick}
          onChange={(e) => {
            onChange(name, e?.target?.value);
          }}
          value={value}
        />
      </CalendarInputWrapper>
    );
  });

  const Calendar = ({ className, children }: any) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: 'relative' }}>{children}</div>
        <Spacer y={4} />
        {hasPresent && (
          <PrimaryButton
            size="tiny"
            onClick={() => {
              if (onChange) {
                onChange(name, 'Present');
              }
            }}
          >
            Present
          </PrimaryButton>
        )}
      </CalendarContainer>
    );
  };

  return (
    <DatePickerWrapper>
      <ErrorBoundary message="Atoms\Sections">
        <DatePicker
          selected={selectedDate}
          wrapperClassName="datepicker"
          dateFormat="MMMM yyyy"
          showMonthYearPicker={true}
          showFullMonthYearPicker={false}
          maxDate={new Date()}
          onChange={(date) => {
            if (!date) return;
            const month = date.toLocaleString('default', { month: 'short' });
            const dateString = `${month} ${date.getFullYear()}`;
            if (onChange) {
              onChange(name, dateString);
            }
          }}
          calendarContainer={Calendar}
          customInput={<CalendarInput />}
          renderCustomHeader={({
            date,
            increaseYear,
            decreaseYear,
            prevYearButtonDisabled,
            nextYearButtonDisabled,
          }) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                  margin: '0',
                }}
              >
                <IconButton onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                  <ChevronLeftIcon color={prevYearButtonDisabled ? Colors.Primary : Colors.White} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    if (onChange) {
                      try {
                        onChange(name, date.getFullYear());
                      } catch (error) {
                        console.error('error', error);
                      }
                    }
                  }}
                >
                  {date.getFullYear()}
                </IconButton>
                <IconButton onClick={increaseYear} disabled={nextYearButtonDisabled}>
                  <ChevronRightIcon color={nextYearButtonDisabled ? Colors.Primary : Colors.White} />
                </IconButton>
              </div>
            );
          }}
        />
      </ErrorBoundary>
    </DatePickerWrapper>
  );
});

const ErrorMessage = styled.div`
  padding: 0 0 0 8px;
  font-size: 16px;
  color: ${Colors.Error};
`;

const CalendarInputWrapper = styled.div`
  position: relative;
  label {
    position: absolute;
    top: -8px;
    left: 10px;
    padding: 0 4px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    background-color: white;
  }
`;

const DatePickerWrapper = styled.div`
  margin-bottom: 16px;
  .datepicker {
    width: 100%;
  }
  .react-datepicker__triangle {
    color: ${Colors.Primary}!important;
    fill: ${Colors.Primary}!important;
    stroke: ${Colors.Primary}!important;
  }
`;

const CalendarContainer = styled.div`
  z-index: 1;
  background-color: white !important;
  opacity: 1 !important;
  border: 1px solid ${Colors.Primary} !important;

  .react-datepicker__header {
    background-color: ${Colors.Primary};
    border-radius: 2px 2px 0 0 !important;
    margin: 0;
    padding: 0;
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${Colors.Contrast} !important;
    color: white !important;
  }

  .react-datepicker__month-text--selected {
    background-color: ${Colors.Primary} !important;
  }

  button {
    border-radius: 0 0 2px 2px !important;
  }
`;

type TextInputProps = {
  tips?: React.ReactNode;
  hasPresent?: boolean;
  name: string;
  noMargin?: boolean;
  value: string;
  onChange: (name: string, value: string) => void;
} & TextFieldProps;

const TextFieldExtended = styled.input`
  &:hover {
    border: 1px solid ${Colors.Black};
    background: none;
  }

  &:focus {
    border: 2px solid ${Colors.Contrast};
    border-left: 1px solid ${Colors.Contrast};
    border-left-width: 6px;
    outline: none;
    padding: 16px 9px;
  }

  width: 100%;
  padding: 14px;
  background: none;
  height: 3.5em;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font: inherit;
`;
