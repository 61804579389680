import { useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { usePersistReducer, initialState } from '../Store';
import { useRouter } from '../../../hooks/useRouter';
import { formatUrl } from '../../../utils/url';
import { resumeContext } from '../Context';
import { Urls } from '../../urls';
import { useUserContext } from '../../../contexts/UserContext';
import { WritingStyle } from '../../../generated/types';

import { useImproveResume } from '../../../graph/mutations/improveResume';
import { useGenerateHightLights } from '../../../graph/mutations/generateHightLights';
import { useFixTypo } from '../../../graph/mutations/fixTypo';
import { useUpdateResume } from '../../../graph/mutations/updateResume';
import { useLazyGetResume } from '../../../graph/queries/getResume';
import { useRenderResume } from '../../../graph/mutations/renderResume';
import { useGetBoard } from '../../../graph/queries/getBoard';
import { useGetJob } from '../../../graph/queries/getJob';

import { Sidebar, SidebarLayout, SidebarContent } from '../../../components/atoms/Layout/Layout';
import { VerticalNav, VerticalNavItem, VerticalNavStatus } from '../../../components/molecules/VerticalNav';
import {
  SuitcaseIcon,
  StarIcon,
  FileIcon,
  PhoneIcon,
  SchoolIcon,
  TrophyIcon,
  LinkIcon,
  FaqIcon,
  SettingsIcon,
  CyclingIcon,
  AddIcon,
  OfficeBuildingIcon,
  UserIcon,
  DesignIcon,
  LanguageIcon,
  PuzzlePieceIcon,
  PageBreakIcon,
  SkillIcon,
} from '../../../components/atoms/Icons';
import { PersonalDetails } from '../Common/PersonalDetails';
import { ContactDetails } from '../Common/ContactDetails';
import { ProfessionalSummary } from '../Common/ProfessionalSummary';
import { AdditionalSection } from '../Common/AdditionalSection';
import { Generic } from '../Common/Generic';
import { DesignSection } from '../Common/DesignSection';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const LOCAL_STORAGE_KEY = 'resume';
const SAVING_TIMEOUT = 5000; // 5 seconds

export const Update = () => {
  const {
    navigate,
    query: { userUuid = '', boardUuid = '', jobUuid = '', resumeUuid = '', stepId = '' },
  } = useRouter();
  const [state, dispatch] = usePersistReducer(LOCAL_STORAGE_KEY, initialState);
  const [updateResume, _updateResumeState] = useUpdateResume();
  const [getResume] = useLazyGetResume({
    variables: { userUuid, boardUuid, jobUuid, resumeUuid, includeDeleted: true },
    fetchPolicy: 'network-only',
  });
  const { data: { board } = {} } = useGetBoard({
    variables: { userUuid, boardUuid },
    fetchPolicy: 'cache-first',
  });
  const { data: { job } = {} } = useGetJob({
    variables: { userUuid, boardUuid, jobUuid },
    fetchPolicy: 'cache-first',
  });
  const [renderResume] = useRenderResume();
  const [improveResume] = useImproveResume();
  const [generateHightLights] = useGenerateHightLights();
  const [fixTypo] = useFixTypo();
  const user = useUserContext();
  const [isVerticalNavOpen, setIsVerticalNavOpen] = useLocalStorage('isResumeUpdateOpen', 'true');

  async function handleSave(): Promise<any> {
    try {
      dispatch({
        type: 'SET_IS_SAVING',
        payload: true,
      });
      await updateResume({
        variables: {
          boardUuid,
          userUuid,
          jobUuid,
          resumeUuid: resumeUuid,
          state,
        },
      });
    } catch (error) {
      console.error(error);
      toast.error('Apologies, we were unable to save the resume. Kindly reload the page and try again later.');
    } finally {
      dispatch({
        type: 'SET_IS_SAVING',
        payload: false,
      });
    }
  }

  async function handleDownload() {
    const { isMember } = user || {};
    if (!isMember) {
      navigate(`/${Urls.Checkout}`);
      return;
    }

    let taskId;
    try {
      taskId = toast.loading('🚀 The resume is been rendered. Please wait', {
        autoClose: 10000,
        position: 'top-left',
      });
      const result = await renderResume({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          state,
        },
      });
      if (result.data?.renderResume) {
        window.open(result.data?.renderResume);
      } else {
        throw new Error('Unable to render the resume.');
      }
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to render the resume. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage);
    } finally {
      toast.dismiss(taskId);
    }
  }

  async function handleImproveResume(
    field: string,
    value: any,
    writingStyle: WritingStyle,
    count = 1
  ): Promise<string[]> {
    const taskId = toast.loading('🤖 The AI is brewing... ☕️', {
      autoClose: 20000,
      position: 'top-left',
    });

    try {
      const { data } = await improveResume({
        variables: {
          boardUuid,
          userUuid,
          data: {
            field,
            value,
            writingStyle,
            count,
          },
        },
      });
      return data?.improveResume || [];
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to improve the resume. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      if (code === 'NOT_ENOUGH_CREDITS') {
        displayMessage = message;
      }
      toast.error(displayMessage);
      return [];
    } finally {
      toast.dismiss(taskId);
    }
  }

  async function handleFixTypo(description: string): Promise<string | undefined> {
    const taskId = toast.loading('The selection is being spell-checked...', {
      autoClose: 20000,
    });

    try {
      const { data } = await fixTypo({
        variables: {
          data: {
            description,
          },
        },
      });
      return data?.fixTypo || '';
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to spell-checked your selected. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      if (code === 'NOT_ENOUGH_CREDITS') {
        displayMessage = message;
      }
      toast.error(displayMessage);
      return;
    } finally {
      toast.dismiss(taskId);
    }
  }

  async function handleAddHightlights(section: string, key: string): Promise<string[] | undefined> {
    try {
      const { data } = await generateHightLights({
        variables: {
          boardUuid,
          userUuid,
          jobUuid,
          resumeUuid,
          data: {
            section,
            key,
          },
        },
      });

      return data?.generateHightLights || [];
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to generate highlights for the selected section. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      if (code === 'NOT_ENOUGH_CREDITS') {
        displayMessage = message;
      }
      toast.error(displayMessage);
      return;
    }
  }

  useEffect(() => {
    async function fetchData() {
      const { data: { resume } = {} } = await getResume({
        fetchPolicy: 'network-only',
      });
      if (resume) {
        dispatch({
          type: 'INITIALIZE_STATE',
          payload: resume.state,
        });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    dispatch({
      type: 'CHANGE_STEP',
      payload: stepId,
    });
  }, [stepId]);

  useEffect(() => {
    const { isDirty } = state;
    let timeoutId: NodeJS.Timeout;

    if (isDirty) {
      timeoutId = setTimeout(() => {
        handleSave();
        dispatch({
          type: 'SET_IS_DIRTY',
          payload: 0,
        });
      }, SAVING_TIMEOUT);
    }
    // Cleanup function to clear the timeout if the component unmounts or form data changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [state.isDirty]);

  const context = {
    title: 'Update resume',
    state,
    dispatch,
  };

  if (!userUuid || !boardUuid) {
    return null;
  }

  return (
    <SidebarLayout>
      <resumeContext.Provider value={context}>
        <Sidebar>
          <VerticalNav isOpen={isVerticalNavOpen} setIsOpen={setIsVerticalNavOpen}>
            <VerticalNavItem
              active={state.currentstep === '1'}
              enabled={true}
              isSmall={true}
              isOpen={isVerticalNavOpen}
              onClick={() => {
                jobUuid
                  ? navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.JobResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        jobUuid,
                        resumeUuid,
                        stepId: '1',
                      })
                    )
                  : navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        resumeUuid,
                        stepId: '1',
                      })
                    );
              }}
              icon={<VerticalNavStatus active={state.currentstep === '1'} enabled={true} icon={<UserIcon />} />}
            >
              Personal details
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === '2'}
              enabled={true}
              isSmall={true}
              isOpen={isVerticalNavOpen}
              onClick={() => {
                jobUuid
                  ? navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.JobResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        jobUuid,
                        resumeUuid,
                        stepId: '2',
                      })
                    )
                  : navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        resumeUuid,
                        stepId: '2',
                      })
                    );
              }}
              icon={<VerticalNavStatus active={state.currentstep === '2'} enabled={true} icon={<PhoneIcon />} />}
            >
              Contact details
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === '3'}
              enabled={true}
              isSmall={true}
              isOpen={isVerticalNavOpen}
              onClick={() => {
                jobUuid
                  ? navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.JobResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        jobUuid,
                        resumeUuid,
                        stepId: '3',
                      })
                    )
                  : navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        resumeUuid,
                        stepId: '3',
                      })
                    );
              }}
              icon={<VerticalNavStatus active={state.currentstep === '3'} enabled={true} icon={<FileIcon />} />}
            >
              Professional Summary
            </VerticalNavItem>

            {state.additionalSections.map((section, index) => (
              <VerticalNavItem
                key={index}
                active={state.currentstep === `${index + 4}`}
                enabled={true}
                isSmall={true}
                isOpen={isVerticalNavOpen}
                onClick={() => {
                  jobUuid
                    ? navigate(
                        formatUrl(`${Urls.ResumeUpdateHome}/${Urls.JobResumeUpdateStepGeneric}`, {
                          userUuid,
                          boardUuid,
                          jobUuid,
                          resumeUuid,
                          stepId: `${index + 4}`,
                        })
                      )
                    : navigate(
                        formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
                          userUuid,
                          boardUuid,
                          resumeUuid,
                          stepId: `${index + 4}`,
                        })
                      );
                }}
                icon={
                  <VerticalNavStatus
                    active={state.currentstep === `${4 + index}`}
                    enabled={true}
                    icon={getIcon(section.section)}
                  />
                }
              >
                {state?.resume?.[section.key]?.title}
              </VerticalNavItem>
            ))}

            <VerticalNavItem
              active={state.currentstep === `${state.additionalSections.length + 4}`}
              enabled={true}
              isSmall={true}
              isOpen={isVerticalNavOpen}
              onClick={() => {
                jobUuid
                  ? navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.JobResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        jobUuid,
                        resumeUuid,
                        stepId: `${state.additionalSections.length + 4}`,
                      })
                    )
                  : navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        resumeUuid,
                        stepId: `${state.additionalSections.length + 4}`,
                      })
                    );
              }}
              icon={
                <VerticalNavStatus
                  active={state.currentstep === `${state.additionalSections.length + 4}`}
                  enabled={true}
                  icon={<AddIcon />}
                />
              }
            >
              Additional Sections
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === `${state.additionalSections.length + 5}`}
              enabled={true}
              isSmall={true}
              isOpen={isVerticalNavOpen}
              onClick={() => {
                jobUuid
                  ? navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.JobResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        jobUuid,
                        resumeUuid,
                        stepId: `${state.additionalSections.length + 5}`,
                      })
                    )
                  : navigate(
                      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
                        userUuid,
                        boardUuid,
                        resumeUuid,
                        stepId: `${state.additionalSections.length + 5}`,
                      })
                    );
              }}
              icon={
                <VerticalNavStatus
                  active={state.currentstep === `${state.additionalSections.length + 5}`}
                  enabled={true}
                  icon={<DesignIcon />}
                />
              }
            >
              Design
            </VerticalNavItem>
          </VerticalNav>
        </Sidebar>
        <SidebarContent>
          {state.currentstep === '1' && (
            <PersonalDetails
              handleSave={handleSave}
              handleDownload={handleDownload}
              isOpen={isVerticalNavOpen}
              board={board}
              job={job}
            />
          )}
          {state.currentstep === '2' && (
            <ContactDetails
              handleSave={handleSave}
              handleDownload={handleDownload}
              isOpen={isVerticalNavOpen}
              board={board}
              job={job}
            />
          )}
          {state.currentstep === '3' && (
            <ProfessionalSummary
              handleSave={handleSave}
              handleDownload={handleDownload}
              handleImproveResume={handleImproveResume}
              handleFixTypo={handleFixTypo}
              isOpen={isVerticalNavOpen}
              board={board}
              job={job}
            />
          )}
          {state.currentstep === `${4 + state.additionalSections.length}` && (
            <AdditionalSection
              handleSave={handleSave}
              handleDownload={handleDownload}
              isOpen={isVerticalNavOpen}
              board={board}
              job={job}
            />
          )}
          {state.currentstep === `${5 + state.additionalSections.length}` && (
            <DesignSection
              handleSave={handleSave}
              handleDownload={handleDownload}
              isOpen={isVerticalNavOpen}
              board={board}
              job={job}
            />
          )}
          {state.additionalSections.length > 0 &&
            parseInt(state.currentstep) > 3 &&
            parseInt(state.currentstep) < 4 + state.additionalSections.length && (
              <Generic
                handleSave={handleSave}
                handleDownload={handleDownload}
                handleImproveResume={handleImproveResume}
                handleFixTypo={handleFixTypo}
                handleAddHightlights={handleAddHightlights}
                isOpen={isVerticalNavOpen}
                board={board}
                job={job}
              />
            )}
          {/* <Pre>
            <pre>{JSON.stringify(state, null, 2)}</pre>
          </Pre> */}
        </SidebarContent>
      </resumeContext.Provider>
    </SidebarLayout>
  );
};

const getIcon = (section: string) => {
  switch (section) {
    case 'employments':
      return <OfficeBuildingIcon />;
    case 'internships':
      return <SuitcaseIcon />;
    case 'educations':
      return <SchoolIcon />;
    case 'certifications':
      return <TrophyIcon />;
    case 'languages':
      return <LanguageIcon />;
    case 'skills':
      return <SkillIcon />;
    case 'hobbies':
      return <CyclingIcon />;
    case 'links':
      return <LinkIcon />;
    case 'references':
      return <FaqIcon />;
    case 'custom':
      return <SettingsIcon />;
    case 'projects':
      return <PuzzlePieceIcon />;
    case 'pagebreak':
      return <PageBreakIcon />;
    default:
      return <StarIcon />;
  }
};

const Pre = styled.pre`
  max-width: 400px;
`;
