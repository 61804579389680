import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useResumeContext } from '../Context';
import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';

import { Board, Job, WritingStyle } from '../../../generated/types';
import { formatUrl } from '../../../utils/url';
import { Colors } from '../../../styles/colors';
import { Urls } from '../../urls';

import { Form } from '../../../components/atoms/Form/Form';
import { WrapperButtons, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { DeleteIcon, EditIcon, NavigateBeforeIcon, NavigateNextIcon } from '../../../components/atoms/Icons';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { Wrapper, WrapperCenter, WrapperLeft, WrapperRight } from './Common.styles';
import { ResumeCustomForm } from '../../../components/molecules/ResumeCustomForm';
import { ResumeLinksForm } from '../../../components/molecules/ResumeLinksForm';
import { IconButton } from '../../../components/atoms/Icons/Icon';
import { ResumeReferencesForm } from '../../../components/molecules/ResumeReferencesForm/ResumeReferencesForm';
import { ResumeLanguagesForm } from '../../../components/molecules/ResumeLanguagesForm';
import { ResumeHobbiesForm } from '../../../components/molecules/ResumeHobbiesForm';
import { ResumeEmploymentsForm } from '../../../components/molecules/ResumeEmploymentsForm';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { ResumePreview } from '../../../components/molecules/ResumePreview';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { ResumeEducationsForm } from '../../../components/molecules/ResumeEducationsForm';
import { FunctionalIFrameComponent } from '../../../components/atoms/FunctionalIFrameComponent';
import { ResumeIntershipsForm } from '../../../components/molecules/ResumeIntershipsForm';
import { ResumeCertificationsForm } from '../../../components/molecules/ResumeCertificationsForm';
import { ResumeSkillsForm } from '../../../components/molecules/ResumeSkillsForm';
import { ResumeProjectsForm } from '../../../components/molecules/ResumeProjectsForm';
import { ResumePageBreakForm } from '../../../components/molecules/ResumePageBreakForm';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { ResumeActions } from '../../../components/molecules/ResumeActions';
import { Descendant } from 'slate';

export const Generic: React.FC<GenericProps> = ({
  handleSave,
  handleDownload,
  handleImproveResume,
  handleAddHightlights,
  handleFixTypo,
  isOpen,
  board,
  job,
}) => {
  const { query: { userUuid, boardUuid = '', jobUuid = '', resumeUuid = '' } = {} } = useRouter();
  const inputRef = useRef(null);
  const { state, dispatch, title } = useResumeContext();
  const [isEditing, setIsEditing] = useState(false);
  const user = useUserContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { resume, isLoading, currentstep, additionalSections, isSaving } = state;
  const currentSection = additionalSections[parseInt(currentstep) - 4];
  const { section, key } = currentSection || {};

  if (!userUuid) {
    return null;
  }

  if (!boardUuid) {
    return null;
  }

  if (!resumeUuid) {
    return null;
  }

  if (!resume) {
    return null;
  }

  let Node = ResumeCustomForm;
  switch (section) {
    case 'custom':
      Node = ResumeCustomForm;
      break;
    case 'skills':
      Node = ResumeSkillsForm;
      break;
    case 'certifications':
      Node = ResumeCertificationsForm;
      break;
    case 'links':
      Node = ResumeLinksForm;
      break;
    case 'references':
      Node = ResumeReferencesForm;
      break;
    case 'languages':
      Node = ResumeLanguagesForm;
      break;
    case 'hobbies':
      Node = ResumeHobbiesForm;
      break;
    case 'internships':
      Node = ResumeIntershipsForm;
      break;
    case 'employments':
      Node = ResumeEmploymentsForm;
      break;
    case 'educations':
      Node = ResumeEducationsForm;
      break;
    case 'projects':
      Node = ResumeProjectsForm;
      break;
    case 'pagebreak':
      Node = ResumePageBreakForm;
      break;
    default:
      break;
  }

  let info = '';
  switch (section) {
    case 'languages':
      info = 'Add languages: name, level, etc.';
      break;
    case 'links':
      info = 'Include links to your portfolio, LinkedIn, or personal website for easy access by hiring managers.';
      break;
    case 'references':
      info = 'Add references: name, title, company, email, phone number, etc.';
      break;
    case 'hobbies':
      info =
        'Showcase your passion and highlight your achievements, such as special projects completed, unique skills developed, or notable experiences gained.';
      break;
    case 'employments':
      info =
        "Highlight your relevant experience from the past decade, using bullet points to showcase achievements. Utilize quantifiable metrics, such as numbers or facts, to illustrate your accomplishments (e.g., 'Attained X, as measured by Y, through Z').";
      break;
    case 'educations':
      info = 'Add your education: degree, school, location, dates, etc.';
      break;
    case 'projects':
      info = 'Add your projects: name, description, link, etc.';
      break;
    case 'pagebreak':
      info = 'Add a page break to your resume.';
      break;
    default:
      break;
  }

  const child = (
    <>
      <Wrapper>
        <WrapperLeft>
          <Spacer y={22} />
          <Breadcrumbs>
            <Breadcrumb>
              <Link to={`/${Urls.Boards}`}>All Boards</Link>
            </Breadcrumb>
            <Breadcrumb>
              <Link
                to={formatUrl(`/${Urls.Boards}/${Urls.BoardView}#resumes`, {
                  userUuid,
                  boardUuid,
                })}
              >
                {board ? board.title : 'Current Board'}
              </Link>
            </Breadcrumb>
            {jobUuid ? (
              <Breadcrumb>
                <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}#resumes`}>
                  {job ? job.jobTitle : 'Current Job'}
                </Link>
              </Breadcrumb>
            ) : null}
            <Breadcrumb>Resume</Breadcrumb>
          </Breadcrumbs>
          <MainTitle>
            <MainTitleLabel>
              {isEditing ? (
                <>
                  <EditableLabel
                    type="text"
                    ref={inputRef}
                    value={state?.resume?.[key]?.title || ''}
                    onChange={(e) => {
                      dispatch({
                        type: 'CHANGE_ADDITIONAL_SECTION_TITLE',
                        payload: { key, title: e.target.value },
                      });
                    }}
                    onBlur={() => setIsEditing(false)}
                  />
                </>
              ) : (
                <>
                  <Label>{state?.resume?.[key]?.title}</Label>
                  <IconButton>
                    <EditIcon
                      size={1.3}
                      onClick={() => {
                        setIsEditing(true);
                        inputRef?.current?.focus();
                      }}
                    />
                  </IconButton>
                </>
              )}
            </MainTitleLabel>
            <IconButton>
              <DeleteIcon
                size={1.3}
                onClick={() => {
                  dispatch({
                    type: 'REMOVE_ADDITIONAL_SECTION',
                    payload: { key },
                  });
                }}
              />
            </IconButton>
          </MainTitle>
          {Boolean(info) && (
            <InfoAlert>
              <p>{info}</p>
            </InfoAlert>
          )}

          {isLoading ? (
            <SkeletonBlock style={{ height: '260px', marginBottom: '32px' }} />
          ) : (
            <Form onSubmit={() => {}}>
              <Node
                dispatch={dispatch}
                resume={resume}
                selectedKey={key}
                handleImproveResume={handleImproveResume}
                handleAddHightlights={handleAddHightlights}
                handleFixTypo={handleFixTypo}
              />

              <WrapperButtons>
                <PrimaryButton
                  onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    await handleSave();
                  }}
                  inline
                  busy={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </PrimaryButton>

                <WrapperButtons>
                  <PrimaryButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.preventDefault();
                      dispatch({
                        type: 'CHANGE_STEP',
                        payload: `${parseInt(currentstep) - 1}`,
                      });
                    }}
                    inline
                    iconLeft={<NavigateBeforeIcon color={Colors.White} size={2} />}
                  >
                    Back
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.preventDefault();
                      dispatch({
                        type: 'CHANGE_STEP',
                        payload: `${parseInt(currentstep) + 1}`,
                      });
                    }}
                    inline
                    iconRight={<NavigateNextIcon color={Colors.White} size={2} />}
                  >
                    Next
                  </PrimaryButton>
                </WrapperButtons>
              </WrapperButtons>
            </Form>
          )}
        </WrapperLeft>
        <WrapperRight>
          {isLoading ? (
            <SkeletonBlock style={{ height: '660px', marginBottom: '32px' }} />
          ) : (
            <>
              <ResumeActions
                user={user}
                userUuid={userUuid}
                boardUuid={boardUuid}
                jobUuid={jobUuid}
                resumeUuid={resumeUuid}
                handleDownload={handleDownload}
              />
              <FunctionalIFrameComponent title="resume">
                <ResumePreview state={state} isOpen={isOpen} />
              </FunctionalIFrameComponent>
            </>
          )}
        </WrapperRight>
      </Wrapper>
    </>
  );

  return (
    <>
      <Helmet title={`${title} ${state?.resume?.[section]?.title}`} />
      {child}
    </>
  );
};

const MainTitle = styled.div`
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MainTitleLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const commonStyles = `
  font-size: var(--font-size-2xl);
  line-height: 0.9;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 16px;
  display: flex;
`;

const Label = styled.div`
  ${commonStyles}
  padding: 1px;
`;

const EditableLabel = styled.input`
  ${commonStyles}
  border: none;
  outline: none;
  width: 100%;
`;

type GenericProps = {
  handleSave: () => Promise<void>;
  handleDownload: () => Promise<void>;
  handleImproveResume: (
    field: string,
    nodes: Descendant[],
    writingStyle: WritingStyle,
    count: number
  ) => Promise<string[]>;
  handleAddHightlights?: () => Promise<string>;
  handleFixTypo?: (selection: string) => Promise<string | undefined>;
  isOpen: boolean;
  job?: Job | null;
  board?: Board | null;
};
