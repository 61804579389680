import { PaintBrush } from '@styled-icons/remix-line';
import { PaintBrush as PaintBrushFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function DesignIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <PaintBrush />
    </Icon>
  );
}

export function DesignFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <PaintBrushFill />
    </Icon>
  );
}
