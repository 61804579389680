import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Descendant } from 'slate';

import { ResumeCustomFormProps } from './ResumeCustomForm.types';

import { AddIcon, DeleteIcon, DragIcon } from '../../atoms/Icons';
import { TextInput } from '../../atoms/Form/TextInput';
import { PaperCollapse } from '../PaperCollapse';
import { IconButton } from '../../atoms/Icons/Icon';
import { SlateEditor } from '../SlateEditor';
import { WritingStyle } from '../../../generated/types';
import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  AddAction,
  AddActionLabel,
  ItemContainer,
  GroupItems,
  HalfItem,
  PaperTitle,
  DraggableContainer,
  Container,
  FullItem,
  SlateEditorWrapper,
} from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';

export const ResumeCustomForm = ({
  dispatch,
  resume,
  handleImproveResume,
  handleFixTypo,
  selectedKey,
}: ResumeCustomFormProps) => {
  const [keyOpened, setKeyOpened] = useState<string>();

  const custom = resume[selectedKey];
  const customItems = custom?.items || [];

  async function onImproveDescription(nodes: Descendant[], writingStyle: WritingStyle) {
    if (!handleImproveResume) return [];

    try {
      let count = 1;
      switch (writingStyle) {
        case WritingStyle.Expand:
          count = 1;
          break;
        case WritingStyle.Shorten:
          count = 1;
          break;
        case WritingStyle.Rewrite:
          count = 2;
          break;
        default:
          count = 1;
          break;
      }
      const result = await handleImproveResume('custom', nodes, writingStyle, count);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return [];
    }
  }

  async function onFixTypo(selection: string) {
    if (!handleFixTypo) return;
    if (!selection) return;
    if (selection.length < 50) {
      toast.error('No selection. Please select at least 50 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }
    if (selection.length > 3000) {
      toast.error('No selection. Please select no more than 3000 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }

    try {
      const result = await handleFixTypo(selection);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return '';
    }
  }

  const onDragEnd = (result: any) => {
    dispatch({
      type: 'REORDER_ADDITIONAL_SECTION_NODE',
      payload: { section: 'custom', sourceIndex: result.source.index, destinationIndex: result.destination.index },
    });
  };

  return (
    <ErrorBoundary message="Molecule\ResumeCustomForm">
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {customItems.map((item, index) => {
                  if (!item?.key) return;
                  return (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided, snap) => {
                        const isDragging = snap.isDragging;
                        return (
                          <DraggableContainer
                            $isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  dispatch({
                                    type: 'REMOVE_ADDITIONAL_SECTION_NODE',
                                    payload: { key: selectedKey, index },
                                  });
                                }}
                              />
                            </IconButton>
                            <Item
                              selectedKey={selectedKey}
                              item={item}
                              index={index}
                              dispatch={dispatch}
                              keyOpened={keyOpened}
                              setKeyOpened={setKeyOpened}
                              isDragging={isDragging}
                              onImproveDescription={onImproveDescription}
                              onFixTypo={onFixTypo}
                            ></Item>
                            <IconButton {...provided.dragHandleProps}>
                              <DragIcon />
                            </IconButton>
                          </DraggableContainer>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Spacer y={16} />

        <AddAction
          onClick={() => {
            dispatch({
              type: 'ADD_ADDITIONAL_SECTION_NODE',
              payload: { key: selectedKey },
            });
          }}
        >
          <AddIcon size={2} />
          <AddActionLabel>Add one more employment record</AddActionLabel>
        </AddAction>

        <Spacer y={32} />
      </Container>
    </ErrorBoundary>
  );
};

const Item = ({
  selectedKey,
  item,
  index,
  dispatch,
  keyOpened,
  setKeyOpened,
  isDragging,
  onImproveDescription,
  onFixTypo,
}: any) => {
  const {
    key,
    header = '',
    subheader = '',
    highlights = [
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
    ],
  } = item;
  const title = header ? header : 'Header';
  const MainTitle = <PaperTitle>{title}</PaperTitle>;
  const isOpen = keyOpened === key && !isDragging;

  return (
    <ItemContainer>
      <PaperCollapse
        onChange={() => {
          setKeyOpened(key);
        }}
        key={`key-${key}-${isOpen}`}
        title={MainTitle}
        open={isOpen}
      >
        {isOpen ? (
          <GroupItems>
            <HalfItem>
              <TextInput
                name="header"
                label="Header"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `[${selectedKey}].items.[${index}].header`, value: e?.target?.value },
                  });
                }}
                value={header}
              />
            </HalfItem>
            <HalfItem>
              <TextInput
                name="subheader"
                label="Subheader"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `[${selectedKey}].items.[${index}].subheader`, value: e?.target?.value },
                  });
                }}
                value={subheader}
              />
            </HalfItem>
            <FullItem>
              <SlateEditorWrapper>
                <SlateEditor
                  onImproveDescription={onImproveDescription}
                  onFixTypo={onFixTypo}
                  onChange={(value) => {
                    dispatch({
                      type: 'CHANGE_FORM_VALUE',
                      payload: { key: `[${selectedKey}].items.[${index}].highlights`, value },
                    });
                  }}
                  style={{ minHeight: '200px', outline: 'none', border: 'none' }}
                  initialValue={highlights}
                />
              </SlateEditorWrapper>
            </FullItem>
          </GroupItems>
        ) : null}
      </PaperCollapse>
    </ItemContainer>
  );
};
