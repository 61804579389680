import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useState } from 'react';

import { ResumeCertificationsFormProps } from './ResumeCertificationsForm.types';

import { AddIcon, DeleteIcon, DragIcon } from '../../atoms/Icons';
import { PaperCollapse } from '../PaperCollapse';
import { IconButton } from '../../atoms/Icons/Icon';
import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  AddAction,
  AddActionLabel,
  ItemContainer,
  GroupItems,
  HalfItem,
  PaperTitle,
  DraggableContainer,
  Container,
  FullItem,
} from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';
import { TextInput } from '../../atoms/Form/TextInput';

export const ResumeCertificationsForm = ({ dispatch, resume }: ResumeCertificationsFormProps) => {
  const [keyOpened, setKeyOpened] = useState<string>(resume.certifications?.items?.[0]?.key || '');

  const { certifications } = resume;
  const customItems = certifications?.items || [];

  const onDragEnd = (result: any) => {
    dispatch({
      type: 'REORDER_ADDITIONAL_SECTION_NODE',
      payload: {
        section: 'certifications',
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    });
  };

  return (
    <ErrorBoundary message="Molecule\ResumeCertificationsForm">
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column">
            {(provided, snap) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {customItems.map((item, index) => {
                  if (!item?.key) return;
                  return (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided, snap) => {
                        const isDragging = snap.isDragging;
                        return (
                          <DraggableContainer
                            $isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  dispatch({
                                    type: 'REMOVE_ADDITIONAL_SECTION_NODE',
                                    payload: { key: 'certifications', index },
                                  });
                                }}
                              />
                            </IconButton>
                            <Item
                              item={item}
                              index={index}
                              dispatch={dispatch}
                              keyOpened={keyOpened}
                              setKeyOpened={setKeyOpened}
                              isDragging={isDragging}
                            ></Item>
                            <IconButton {...provided.dragHandleProps}>
                              <DragIcon />
                            </IconButton>
                          </DraggableContainer>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Spacer y={16} />

        <AddAction
          onClick={() => {
            dispatch({
              type: 'ADD_ADDITIONAL_SECTION_NODE',
              payload: { key: `certifications` },
            });
          }}
        >
          <AddIcon size={2} />
          <AddActionLabel>Add one more certification</AddActionLabel>
        </AddAction>

        <Spacer y={32} />
      </Container>
    </ErrorBoundary>
  );
};

const Item = ({ item, index, dispatch, keyOpened, setKeyOpened, isDragging }: any) => {
  const { key, title = '', issuer = '', date = '', url = '' } = item;

  const MainTitle = <PaperTitle>{title ? title : 'Certification'}</PaperTitle>;
  const isOpen = keyOpened === key && !isDragging;

  return (
    <ItemContainer $isDragging={isDragging}>
      <PaperCollapse
        onChange={() => {
          setKeyOpened(key);
        }}
        key={`key-${key}-${isOpen}`}
        title={MainTitle}
        open={isOpen}
      >
        {isOpen ? (
          <GroupItems>
            <FullItem>
              <TextInput
                name="title"
                label="title"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `certifications.items[${index}].title`, value: e?.target?.value },
                  });
                }}
                value={title}
                noMargin={true}
              />
            </FullItem>
            <HalfItem>
              <TextInput
                name="issuer"
                label="issuer"
                type="text"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `certifications.items[${index}].issuer`, value: e?.target?.value },
                  });
                }}
                value={issuer}
                noMargin={true}
              />
            </HalfItem>
            <HalfItem>
              <TextInput
                name="date"
                label="date"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `certifications.items[${index}].date`, value: e?.target?.value },
                  });
                }}
                value={date}
                noMargin={true}
              />
            </HalfItem>
            <FullItem>
              <TextInput
                name="url"
                label="url"
                type="url"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `certifications.items[${index}].url`, value: e?.target?.value },
                  });
                }}
                value={url}
                noMargin={true}
              />
            </FullItem>
          </GroupItems>
        ) : null}
      </PaperCollapse>
    </ItemContainer>
  );
};
