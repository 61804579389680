import { createContext, useContext } from 'react';
import { ResumeStoreState } from '../Store';

interface ResumeContext {
  title: string;
  state: ResumeStoreState;
  dispatch: any;
  updateResume: any;
}

export const resumeContext = createContext<ResumeContext>({} as ResumeContext);

export const useResumeContext = () => {
  return useContext(resumeContext);
};
