import { Earth } from '@styled-icons/remix-line';
import { Earth as EarthFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function LanguageIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Earth />
    </Icon>
  );
}

export function LanguageFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <EarthFill />
    </Icon>
  );
}
