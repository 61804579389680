import { Instagram } from '@styled-icons/remix-line';
import { Instagram as InstagramFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function InstagramIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Instagram />
    </Icon>
  );
}

export function InstagramFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <InstagramFill />
    </Icon>
  );
}
