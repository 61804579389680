import { PageBreak } from '@styled-icons/icomoon';

import { Icon, IconProps } from './Icon';

export function PageBreakIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <PageBreak />
    </Icon>
  );
}
