import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useState } from 'react';

import { ResumeReferencesFormProps } from './ResumeReferencesForm.types';

import { AddIcon, DeleteIcon, DragIcon } from '../../atoms/Icons';
import { PaperCollapse } from '../PaperCollapse';
import { IconButton } from '../../atoms/Icons/Icon';
import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  AddAction,
  AddActionLabel,
  ItemContainer,
  GroupItems,
  HalfItem,
  PaperTitle,
  DraggableContainer,
  Container,
  FullItem,
} from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';
import { TextInput } from '../../atoms/Form/TextInput';
import { CheckboxInput } from '../../atoms/Form/CheckboxInput';
import { Copy } from '../../atoms/Typography';

export const ResumeReferencesForm = ({ dispatch, resume }: ResumeReferencesFormProps) => {
  const [keyOpened, setKeyOpened] = useState<string>(resume.references?.items?.[0]?.key || '');

  const { references } = resume;
  const { onDemand } = references || {};
  const customItems = references?.items || [];

  const onDragEnd = (result: any) => {
    dispatch({
      type: 'REORDER_ADDITIONAL_SECTION_NODE',
      payload: { section: 'references', sourceIndex: result.source.index, destinationIndex: result.destination.index },
    });
  };

  return (
    <ErrorBoundary message="Molecule\ResumeLanguagesForm">
      <Container>
        <GroupItems>
          <FullItem>
            <CheckboxInput
              isChecked={onDemand}
              name="onDemand"
              onClick={(event) => {
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'references.onDemand', value: event.target.checked },
                });
              }}
              label={
                <>
                  <Copy marginBottom={0} styleLevel={2}>
                    References available upon request
                  </Copy>
                </>
              }
              noMargin
            />
          </FullItem>
        </GroupItems>
        <Spacer y={16} />

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {customItems.map((item, index) => {
                  if (!item?.key) return;
                  return (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided, snap) => {
                        const isDragging = snap.isDragging;
                        return (
                          <DraggableContainer
                            $isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  dispatch({
                                    type: 'REMOVE_ADDITIONAL_SECTION_NODE',
                                    payload: { key: 'references', index },
                                  });
                                }}
                              />
                            </IconButton>
                            <Item
                              item={item}
                              index={index}
                              dispatch={dispatch}
                              keyOpened={keyOpened}
                              setKeyOpened={setKeyOpened}
                              isDragging={isDragging}
                            ></Item>
                            <IconButton {...provided.dragHandleProps}>
                              <DragIcon />
                            </IconButton>
                          </DraggableContainer>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Spacer y={16} />

        <AddAction
          onClick={() => {
            dispatch({
              type: 'ADD_ADDITIONAL_SECTION_NODE',
              payload: { key: `references` },
            });
          }}
        >
          <AddIcon size={2} />
          <AddActionLabel>Add one more references</AddActionLabel>
        </AddAction>

        <Spacer y={32} />
      </Container>
    </ErrorBoundary>
  );
};

const Item = ({ item, index, dispatch, keyOpened, setKeyOpened, isDragging }: any) => {
  const { key, name = '', email = '', phone = '', company = '', linkedIn = '' } = item;
  const title = name ? name : 'Reference';
  const MainTitle = <PaperTitle>{title}</PaperTitle>;
  const isOpen = keyOpened === key && !isDragging;

  return (
    <ItemContainer $isDragging={isDragging}>
      <PaperCollapse
        onChange={() => {
          setKeyOpened(key);
        }}
        key={`key-${key}-${isOpen}`}
        title={MainTitle}
        open={isOpen}
      >
        {isOpen ? (
          <GroupItems>
            <HalfItem>
              <TextInput
                name="name"
                label="Reference Name"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `references.items[${index}].name`, value: e?.target?.value },
                  });
                }}
                value={name}
                noMargin={true}
              />
            </HalfItem>
            <HalfItem>
              <TextInput
                name="company"
                label="Reference Company"
                type="text"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `references.items[${index}].company`, value: e?.target?.value },
                  });
                }}
                value={company}
                noMargin={true}
              />
            </HalfItem>
            <HalfItem>
              <TextInput
                name="email"
                label="Reference Email"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `references.items[${index}].email`, value: e?.target?.value },
                  });
                }}
                value={email}
                noMargin={true}
              />
            </HalfItem>
            <HalfItem>
              <TextInput
                name="phone"
                label="Reference Phone"
                type="text"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `references.items[${index}].phone`, value: e?.target?.value },
                  });
                }}
                value={phone}
                noMargin={true}
              />
            </HalfItem>
            <FullItem>
              <TextInput
                name="phone"
                label="LinkedIn"
                type="url"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `references.items[${index}].linkedIn`, value: e?.target?.value },
                  });
                }}
                value={linkedIn}
                noMargin={true}
              />
            </FullItem>
          </GroupItems>
        ) : null}
      </PaperCollapse>
    </ItemContainer>
  );
};
