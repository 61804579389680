import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { ResumeReorderSectionsProps } from './ResumeReorderSections.types';
import { Action, ActionLabel, Container, DraggableContainer, Items } from './ResumeReorderSections.styles';
import {
  CheckIcon,
  CyclingIcon,
  DragIcon,
  FaqIcon,
  LanguageIcon,
  LinkIcon,
  OfficeBuildingIcon,
  PageBreakIcon,
  PuzzlePieceIcon,
  SchoolIcon,
  SettingsIcon,
  StarIcon,
  SuitcaseIcon,
  TrophyIcon,
} from '../../atoms/Icons';
import { ErrorBoundary } from '../ErrorBoundary';
import { IconButton } from '../../atoms/Icons/Icon';
import { H3 } from '../../atoms/Typography';

export const ResumeReorderSections = ({ state, dispatch }: ResumeReorderSectionsProps) => {
  const { additionalSections, resume } = state;
  if (!additionalSections) return null;

  const onDragEnd = (result: any) => {
    dispatch({
      type: 'REORDER_ADDITIONAL_SECTION',
      payload: { sourceIndex: result.source.index, destinationIndex: result.destination.index },
    });
  };

  return (
    <ErrorBoundary message="Molecule\ResumeReorderSections">
      <Container>
        <H3>Reorder Sections</H3>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column">
            {(provided) => (
              <Items ref={provided.innerRef} {...provided.droppableProps}>
                {additionalSections.map(({ key, section }, index) => {
                  if (!key) return;
                  return (
                    <Draggable key={key} draggableId={key} index={index}>
                      {(provided, snap) => {
                        const isDragging = snap.isDragging;
                        return (
                          <DraggableContainer
                            $isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Section title={resume[key]?.title || ''} section={section}>
                              <IconButton {...provided.dragHandleProps}>
                                <DragIcon />
                              </IconButton>
                            </Section>
                          </DraggableContainer>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Items>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </ErrorBoundary>
  );
};

interface Props {
  item: {
    index: number;
    key: string;
    section: string;
    title: string;
  };
}

const Section = ({ section, title, children }: any) => {
  let Icon = getIcon(section);
  return (
    <Action>
      <ActionLabel>
        <Icon size={1.5} />
        {title}
      </ActionLabel>
      {children}
    </Action>
  );
};

const getIcon = (section: string) => {
  switch (section) {
    case 'employments':
      return OfficeBuildingIcon;
    case 'internships':
      return SuitcaseIcon;
    case 'educations':
      return SchoolIcon;
    case 'certifications':
      return TrophyIcon;
    case 'languages':
      return LanguageIcon;
    case 'skills':
      return CheckIcon;
    case 'hobbies':
      return CyclingIcon;
    case 'links':
      return LinkIcon;
    case 'references':
      return FaqIcon;
    case 'custom':
      return SettingsIcon;
    case 'projects':
      return PuzzlePieceIcon;
    case 'pagebreak':
      return PageBreakIcon;
    default:
      return StarIcon;
  }
};
