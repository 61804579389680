import { ResumeBasicFormProps } from './ResumeBasicForm.types';

import { AddIcon, RemoveIcon } from '../../atoms/Icons';
import { TextInput } from '../../atoms/Form/TextInput';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { AddActionLabel, Container, FullItem, GroupItems, HalfItem } from '../ResumeCommonForm';
import { AddAction } from './ResumeBasicForm.styles';
import { ErrorBoundary } from '../ErrorBoundary';
import { CheckboxInput } from '../../atoms/Form/CheckboxInput';
import { Copy } from '../../atoms/Typography';

export const ResumeBasicForm = ({ dispatch, resume }: ResumeBasicFormProps) => {
  const basics = resume?.basics || {};
  const {
    firstName = '',
    lastName = '',
    jobTitle = '',
    nationality = '',
    drivingLicense = '',
    dateOfBirth = '',
    placeOfBirth = '',
    isOpen = false,
    workRightUK = false,
    workRightEU = false,
    availability = '',
  } = basics;
  return (
    <ErrorBoundary message="Molecule\ResumeBasicForm">
      <Container>
        <GroupItems>
          <HalfItem>
            <TextInput
              name="firstName"
              type="text"
              label="First Name"
              placeholder="John"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'basics.firstName', value: e?.target?.value },
                });
              }}
              value={firstName}
            />
          </HalfItem>
          <HalfItem>
            <TextInput
              name="lastName"
              type="text"
              label="Last Name"
              placeholder="John"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'basics.lastName', value: e?.target?.value },
                });
              }}
              value={lastName}
            />
          </HalfItem>
          <HalfItem>
            <TextInput
              name="jobTitle"
              type="text"
              label="Job Title"
              placeholder="John"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'basics.jobTitle', value: e?.target?.value },
                });
              }}
              value={jobTitle}
            />
          </HalfItem>
          <FullItem>
            <AddAction
              onClick={() => {
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'basics.isOpen', value: !isOpen },
                });
              }}
              $isOpen={isOpen}
            >
              {isOpen ? (
                <>
                  <RemoveIcon size={2} />
                  <AddActionLabel>Hide additional details</AddActionLabel>
                </>
              ) : (
                <>
                  <AddIcon size={2} />
                  <AddActionLabel>Show additional details</AddActionLabel>
                </>
              )}
            </AddAction>
            <Spacer y={16} />
          </FullItem>
          {isOpen && (
            <HalfItem>
              <TextInput
                name="drivingLicense"
                type="text"
                label="Driving License"
                placeholder="e.g. Full UK Driving License"
                noMargin={true}
                onChange={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'basics.drivingLicense', value: e.target.value },
                  });
                }}
                value={drivingLicense}
              />
            </HalfItem>
          )}
          {isOpen && (
            <HalfItem>
              <TextInput
                name="nationality"
                type="text"
                label="Nationality"
                placeholder="e.g. British"
                noMargin={true}
                onChange={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'basics.nationality', value: e.target.value },
                  });
                }}
                value={nationality}
              />
            </HalfItem>
          )}
          {isOpen && (
            <HalfItem>
              <TextInput
                name="dateOfBirth"
                type="text"
                label="Date of Birth"
                placeholder="John"
                noMargin={true}
                onChange={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'basics.dateOfBirth', value: e.target.value },
                  });
                }}
                value={dateOfBirth}
              />
            </HalfItem>
          )}
          {isOpen && (
            <HalfItem>
              <TextInput
                name="placeOfBirth"
                type="text"
                label="Place of Birth"
                placeholder="e.g. London, UK"
                noMargin={true}
                onChange={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'basics.placeOfBirth', value: e.target.value },
                  });
                }}
                value={placeOfBirth}
              />
            </HalfItem>
          )}
          {isOpen && (
            <HalfItem>
              <TextInput
                name="availability"
                type="text"
                label="Availability"
                placeholder="e.g. Immediately, 1 month notice"
                noMargin={true}
                onChange={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'basics.availability', value: e.target.value },
                  });
                }}
                value={availability}
              />
            </HalfItem>
          )}
          {isOpen && <HalfItem></HalfItem>}
          {isOpen && (
            <HalfItem>
              <CheckboxInput
                isChecked={workRightUK}
                name="workRightUK"
                onClick={(event) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'basics.workRightUK', value: event.target.checked },
                  });
                }}
                label={
                  <>
                    <Copy marginBottom={0} styleLevel={2}>
                      Right to work in the UK
                    </Copy>
                  </>
                }
                noMargin
              />
            </HalfItem>
          )}
          {isOpen && (
            <HalfItem>
              <CheckboxInput
                isChecked={workRightEU}
                name="workRightEU"
                onClick={(event) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'basics.workRightEU', value: event.target.checked },
                  });
                }}
                label={
                  <>
                    <Copy marginBottom={0} styleLevel={2}>
                      Right to work in the EU
                    </Copy>
                  </>
                }
                noMargin
              />
            </HalfItem>
          )}
        </GroupItems>
      </Container>
    </ErrorBoundary>
  );
};
