import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ResumePageBreakFormProps } from './ResumePageBreakForm.types';

import { AddIcon, DeleteIcon, DragIcon } from '../../atoms/Icons';
import { TextInput } from '../../atoms/Form/TextInput';
import { PaperCollapse } from '../PaperCollapse';
import { IconButton } from '../../atoms/Icons/Icon';
import { SlateEditor } from '../SlateEditor';
import { DayMonthTimePicker } from '../../atoms/Form/DayMonthTimePicker';
import { WritingStyle } from '../../../generated/types';
import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  AddAction,
  AddActionLabel,
  ItemContainer,
  GroupItems,
  HalfItem,
  PaperTitle,
  DraggableContainer,
  Container,
  FullItem,
  SlateEditorWrapper,
} from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';

export const ResumePageBreakForm = () => {
  return (
    <ErrorBoundary message="Molecule\ResumePageBreakForm">
      <Container>
        <Spacer y={16} />
      </Container>
    </ErrorBoundary>
  );
};
