import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { ResumeProjectsFormProps } from './ResumeProjectsForm.types';

import { AddIcon, DeleteIcon, DragIcon } from '../../atoms/Icons';
import { TextInput } from '../../atoms/Form/TextInput';
import { PaperCollapse } from '../PaperCollapse';
import { IconButton } from '../../atoms/Icons/Icon';
import { SlateEditor } from '../SlateEditor';
import { DayMonthTimePicker } from '../../atoms/Form/DayMonthTimePicker';
import { WritingStyle } from '../../../generated/types';
import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  AddAction,
  AddActionLabel,
  ItemContainer,
  GroupItems,
  HalfItem,
  PaperTitle,
  DraggableContainer,
  Container,
  FullItem,
  SlateEditorWrapper,
} from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';
import { ResumeProjectStore } from '../../../routes/Resume/Store';
import { Descendant } from 'slate';

export const ResumeProjectsForm = ({
  dispatch,
  resume,
  handleImproveResume,
  handleFixTypo,
}: ResumeProjectsFormProps) => {
  const [keyOpened, setKeyOpened] = useState<string>(resume.projects?.items?.[0]?.key || '');

  const { projects } = resume;
  const customItems = projects?.items || [];

  async function onImproveDescription(nodes: Descendant[], writingStyle: WritingStyle) {
    if (!handleImproveResume) return [];

    try {
      let count = 1;
      switch (writingStyle) {
        case WritingStyle.Expand:
          count = 1;
          break;
        case WritingStyle.Shorten:
          count = 1;
          break;
        case WritingStyle.Rewrite:
          count = 2;
          break;
        default:
          count = 1;
          break;
      }
      const result = await handleImproveResume('projects', nodes, writingStyle, count);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return [];
    }
  }

  async function onFixTypo(selection: string) {
    if (!handleFixTypo) return;
    if (!selection) return;
    if (selection.length < 50) {
      toast.error('No selection. Please select at least 50 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }
    if (selection.length > 3000) {
      toast.error('No selection. Please select no more than 3000 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }

    try {
      const result = await handleFixTypo(selection);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return '';
    }
  }

  const onDragEnd = (result: any) => {
    dispatch({
      type: 'REORDER_ADDITIONAL_SECTION_NODE',
      payload: { section: 'projects', sourceIndex: result.source.index, destinationIndex: result.destination.index },
    });
  };

  return (
    <ErrorBoundary message="Molecule\ResumeProjectsForm">
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column">
            {(provided, snap) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {customItems.map((item, index) => {
                  if (!item?.key) return;
                  return (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided, snap) => {
                        const isDragging = snap.isDragging;
                        return (
                          <DraggableContainer
                            $isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  dispatch({
                                    type: 'REMOVE_ADDITIONAL_SECTION_NODE',
                                    payload: { key: 'projects', index },
                                  });
                                }}
                              />
                            </IconButton>
                            <Item
                              item={item}
                              index={index}
                              dispatch={dispatch}
                              keyOpened={keyOpened}
                              setKeyOpened={setKeyOpened}
                              isDragging={isDragging}
                              onImproveDescription={onImproveDescription}
                              onFixTypo={onFixTypo}
                            ></Item>
                            <IconButton {...provided.dragHandleProps}>
                              <DragIcon />
                            </IconButton>
                          </DraggableContainer>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Spacer y={16} />

        <AddAction
          onClick={() => {
            dispatch({
              type: 'ADD_ADDITIONAL_SECTION_NODE',
              payload: { key: `projects` },
            });
          }}
        >
          <AddIcon />
          <AddActionLabel>Add one more project record</AddActionLabel>
        </AddAction>

        <Spacer y={32} />
      </Container>
    </ErrorBoundary>
  );
};

const Item = ({ item, index, dispatch, keyOpened, setKeyOpened, isDragging, onImproveDescription, onFixTypo }: any) => {
  const {
    key,
    name = '',
    date = '',
    url = '',
    highlights = [
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
    ],
  } = item as ResumeProjectStore;
  const title = name ? name : 'Project';
  const MainTitle = <PaperTitle>{title}</PaperTitle>;
  const isOpen = keyOpened === key && !isDragging;
  const handleMonthChange = useCallback((name: string, date: string) => {
    dispatch({
      type: 'CHANGE_FORM_VALUE',
      payload: { key: `projects.items.[${index}].[${name}]`, value: date },
    });
  }, []);

  return (
    <ItemContainer $isDragging={isDragging}>
      <PaperCollapse
        onChange={() => {
          setKeyOpened(key);
        }}
        key={`key-${key}-${isOpen}`}
        title={MainTitle}
        open={isOpen}
      >
        {isOpen ? (
          <GroupItems>
            <HalfItem>
              <TextInput
                name="name"
                label="Name"
                placeholder="e.g. Build a personal website"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `projects.items.[${index}].name`, value: e?.target?.value },
                  });
                }}
                value={name}
                noMargin={true}
              ></TextInput>
            </HalfItem>
            <HalfItem>
              <DayMonthTimePicker
                name="date"
                label="Date"
                onChange={handleMonthChange}
                value={date}
                noMargin={true}
              ></DayMonthTimePicker>
            </HalfItem>
            <FullItem>
              <TextInput
                name="url"
                label="Url"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `projects.items.[${index}].url`, value: e?.target?.value },
                  });
                }}
                value={url}
                noMargin={true}
              />
            </FullItem>
            <FullItem>
              <SlateEditorWrapper>
                <SlateEditor
                  onImproveDescription={onImproveDescription}
                  onFixTypo={onFixTypo}
                  onChange={(value) => {
                    dispatch({
                      type: 'CHANGE_FORM_VALUE',
                      payload: { key: `projects.items.[${index}].highlights`, value },
                    });
                  }}
                  style={{ minHeight: '200px', outline: 'none', border: 'none' }}
                  initialValue={highlights}
                />
              </SlateEditorWrapper>
            </FullItem>
          </GroupItems>
        ) : null}
      </PaperCollapse>
    </ItemContainer>
  );
};
