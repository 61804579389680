import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { useResumeContext } from '../Context';
import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';

import { formatUrl } from '../../../utils/url';
import { Colors } from '../../../styles/colors';
import { Urls } from '../../urls';

import { Form } from '../../../components/atoms/Form/Form';
import { H3 } from '../../../components/atoms/Typography/Headings';
import { WrapperButtons, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { NavigateNextIcon } from '../../../components/atoms/Icons';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { Wrapper, WrapperLeft, WrapperRight } from './Common.styles';
import { ResumeBasicForm } from '../../../components/molecules/ResumeBasicForm';
import { ResumePreview } from '../../../components/molecules/ResumePreview';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { FunctionalIFrameComponent } from '../../../components/atoms/FunctionalIFrameComponent';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { ResumeActions } from '../../../components/molecules/ResumeActions';
import { Board, Job } from '../../../generated/types';

export const PersonalDetails: React.FC<PersonalDetailsProps> = ({ handleSave, handleDownload, isOpen, board, job }) => {
  const memoizedHandleDownload = useMemo(() => handleDownload, []);
  const { query: { userUuid, boardUuid = '', jobUuid = '', resumeUuid = '' } = {} } = useRouter();
  const { state, dispatch, title } = useResumeContext();
  const user = useUserContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { resume, isLoading, isSaving } = state;

  if (!userUuid) {
    return null;
  }

  if (!boardUuid) {
    return null;
  }

  if (!resumeUuid) {
    return null;
  }

  const child = (
    <Wrapper>
      <WrapperLeft>
        <Spacer y={22} />
        <Breadcrumbs>
          <Breadcrumb>
            <Link to={`/${Urls.Boards}`}>All Boards</Link>
          </Breadcrumb>
          <Breadcrumb>
            <Link
              to={formatUrl(`/${Urls.Boards}/${Urls.BoardView}#resumes`, {
                userUuid,
                boardUuid,
              })}
            >
              {board ? board.title : 'Current Board'}
            </Link>
          </Breadcrumb>
          {jobUuid ? (
            <Breadcrumb>
              <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}#resumes`}>
                {job ? job.jobTitle : 'Current Job'}
              </Link>
            </Breadcrumb>
          ) : null}
          <Breadcrumb>Resume</Breadcrumb>
        </Breadcrumbs>
        <H3>Personal details</H3>
        <InfoAlert>
          <p>
            Including personal details like the name and job title in a resume is crucial as it provides the recruiter
            with a swift and informative snapshot of the candidate.
          </p>
        </InfoAlert>
        {isLoading ? (
          <SkeletonBlock style={{ height: '260px', marginBottom: '32px' }} />
        ) : (
          <Form onSubmit={() => {}}>
            <ResumeBasicForm resume={resume} dispatch={dispatch} />

            <Spacer y={32} />

            <WrapperButtons>
              <PrimaryButton
                onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.preventDefault();
                  await handleSave();
                }}
                inline
                busy={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </PrimaryButton>

              <WrapperButtons>
                <PrimaryButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    dispatch({
                      type: 'CHANGE_STEP',
                      payload: '2',
                    });
                  }}
                  inline
                  iconRight={<NavigateNextIcon color={Colors.White} size={2} />}
                >
                  Next
                </PrimaryButton>
              </WrapperButtons>
            </WrapperButtons>
          </Form>
        )}
      </WrapperLeft>
      <WrapperRight>
        {isLoading ? (
          <SkeletonBlock style={{ height: '660px', marginBottom: '32px' }} />
        ) : (
          <>
            <ResumeActions
              userUuid={userUuid}
              boardUuid={boardUuid}
              jobUuid={jobUuid}
              user={user}
              job={job}
              board={board}
              resumeUuid={resumeUuid}
              handleDownload={memoizedHandleDownload}
            />
            <FunctionalIFrameComponent title="resume">
              <ResumePreview state={state} isOpen={isOpen} />
            </FunctionalIFrameComponent>
          </>
        )}
      </WrapperRight>
    </Wrapper>
  );

  return (
    <>
      <Helmet title={`${title} - Personal details`} />
      {child}
    </>
  );
};

type PersonalDetailsProps = {
  isOpen: boolean;
  job?: Job | null;
  board?: Board | null;
  handleSave: () => Promise<void>;
  handleDownload: () => Promise<void>;
};
