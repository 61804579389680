import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { ResumeEducationsFormProps } from './ResumeEducationsForm.types';

import { AddIcon, DeleteIcon, DragIcon } from '../../atoms/Icons';
import { TextInput } from '../../atoms/Form/TextInput';
import { PaperCollapse } from '../PaperCollapse';
import { IconButton } from '../../atoms/Icons/Icon';
import { AutocompleteInput } from '../../atoms/Form/AutocompleteInput';
import { SlateEditor } from '../SlateEditor';
import { DayMonthTimePicker } from '../../atoms/Form/DayMonthTimePicker';
import { WritingStyle } from '../../../generated/types';
import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  AddAction,
  AddActionLabel,
  ItemContainer,
  GroupItems,
  HalfItem,
  PaperTitle,
  DraggableContainer,
  Container,
  FullItem,
  SlateEditorWrapper,
  QuarterItem,
} from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';

export const ResumeEducationsForm = ({
  dispatch,
  resume,
  handleImproveDescription,
  handleFixTypo,
}: ResumeEducationsFormProps) => {
  const [keyOpened, setKeyOpened] = useState<string>(resume.educations?.items?.[0]?.key || '');

  const { educations } = resume;
  const customItems = educations?.items || [];

  async function onImproveDescription(selection: string, writingStyle: WritingStyle) {
    if (!handleImproveDescription) return;
    if (!selection) return;
    if (selection.length < 100) {
      toast.error('No selection. Please select at least 100 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }
    if (selection.length > 1000) {
      toast.error('No selection. Please select no more than 1000 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }

    try {
      let count = 1;
      switch (writingStyle) {
        case WritingStyle.Expand:
          count = 1;
          break;
        case WritingStyle.Shorten:
          count = 1;
          break;
        case WritingStyle.Rewrite:
          count = 2;
          break;
        default:
          count = 1;
          break;
      }
      const result = await handleImproveDescription(selection, writingStyle, count);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return '';
    }
  }

  async function onFixTypo(selection: string) {
    if (!handleFixTypo) return;
    if (!selection) return;
    if (selection.length < 50) {
      toast.error('No selection. Please select at least 50 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }
    if (selection.length > 3000) {
      toast.error('No selection. Please select no more than 3000 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }

    try {
      const result = await handleFixTypo(selection);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return '';
    }
  }

  const onDragEnd = (result: any) => {
    dispatch({
      type: 'REORDER_ADDITIONAL_SECTION_NODE',
      payload: { section: 'educations', sourceIndex: result.source.index, destinationIndex: result.destination.index },
    });
  };

  return (
    <ErrorBoundary message="Molecule\ResumeEducationsForm">
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column">
            {(provided, _snap) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {customItems.map((item, index) => {
                  if (!item?.key) return;
                  return (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided, snap) => {
                        const isDragging = snap.isDragging;
                        return (
                          <DraggableContainer
                            $isDragging={isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  dispatch({
                                    type: 'REMOVE_ADDITIONAL_SECTION_NODE',
                                    payload: { key: 'educations', index },
                                  });
                                }}
                              />
                            </IconButton>
                            <Item
                              item={item}
                              index={index}
                              dispatch={dispatch}
                              keyOpened={keyOpened}
                              setKeyOpened={setKeyOpened}
                              isDragging={isDragging}
                              onImproveDescription={onImproveDescription}
                              onFixTypo={onFixTypo}
                            ></Item>
                            <IconButton {...provided.dragHandleProps}>
                              <DragIcon />
                            </IconButton>
                          </DraggableContainer>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Spacer y={16} />

        <AddAction
          onClick={() => {
            dispatch({
              type: 'ADD_ADDITIONAL_SECTION_NODE',
              payload: { key: `educations` },
            });
          }}
        >
          <AddIcon />
          <AddActionLabel>Add one more education record</AddActionLabel>
        </AddAction>

        <Spacer y={32} />
      </Container>
    </ErrorBoundary>
  );
};

const Item = ({ item, index, dispatch, keyOpened, setKeyOpened, isDragging, onImproveDescription, onFixTypo }: any) => {
  const {
    key,
    institution = '',
    studyType = '',
    startDate = '',
    endDate = '',
    location = '',
    area = '',
    score = '',
    highlights = [
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
    ],
  } = item;
  const title = institution ? institution : 'Education';
  const MainTitle = <PaperTitle>{title}</PaperTitle>;
  const isOpen = keyOpened === key && !isDragging;
  const handleMonthChange = useCallback((name: string, date: string) => {
    dispatch({
      type: 'CHANGE_FORM_VALUE',
      payload: { key: `educations.items.[${index}].[${name}]`, value: date },
    });
  }, []);

  return (
    <ItemContainer $isDragging={isDragging}>
      <PaperCollapse
        onChange={() => {
          setKeyOpened(key);
        }}
        key={`key-${key}-${isOpen}`}
        title={MainTitle}
        open={isOpen}
      >
        {isOpen ? (
          <GroupItems>
            <HalfItem>
              <TextInput
                name="institution"
                label="Institution"
                placeholder="e.g. Harvard University"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `educations.items.[${index}].institution`, value: e?.target?.value },
                  });
                }}
                value={institution}
                noMargin={true}
              ></TextInput>
            </HalfItem>
            <HalfItem>
              <TextInput
                name="studyType"
                label="Degree"
                placeholder="e.g. Bachelor of Science, Master of Arts"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `educations.items.[${index}].studyType`, value: e?.target?.value },
                  });
                }}
                value={studyType}
                noMargin={true}
              ></TextInput>
            </HalfItem>
            <HalfItem>
              <TextInput
                name="area"
                label="Area"
                placeholder="e.g. Civil Engineering"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `educations.items.[${index}].area`, value: e?.target?.value },
                  });
                }}
                value={area}
                noMargin={true}
              ></TextInput>
            </HalfItem>
            <HalfItem>
              <TextInput
                name="score"
                label="Score"
                placeholder="e.g. Mention B"
                onChange={(e) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `educations.items.[${index}].score`, value: e?.target?.value },
                  });
                }}
                value={score}
                noMargin={true}
              ></TextInput>
            </HalfItem>
            <HalfItem>
              <AutocompleteInput
                options={commonCities}
                name="location"
                label="Location"
                placeholder='e.g. "London, UK"'
                defaultValue={location}
                onChange={(value) => {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: `educations.items.[${index}].location`, value: value },
                  });
                }}
                // onSelect={(e) => {
                //   dispatch({
                //     type: 'CHANGE_FORM_VALUE',
                //     payload: { key: `educations.items.[${index}].location`, value: e?.target?.value },
                //   });
                // }}
                // onBlur={(e) => {
                //   dispatch({
                //     type: 'CHANGE_FORM_VALUE',
                //     payload: { key: `educations.items.[${index}].location`, value: e?.target?.value },
                //   });
                // }}
                value={location}
                noMargin={true}
              ></AutocompleteInput>
            </HalfItem>
            <QuarterItem>
              <DayMonthTimePicker
                name="startDate"
                label="Start Date"
                onChange={handleMonthChange}
                value={startDate}
                noMargin={true}
              />
            </QuarterItem>
            <QuarterItem>
              <DayMonthTimePicker
                name="endDate"
                label="End Date"
                onChange={handleMonthChange}
                value={endDate}
                hasPresent={true}
                noMargin={true}
              />
            </QuarterItem>
            <FullItem>
              <SlateEditorWrapper>
                <SlateEditor
                  onImproveDescription={onImproveDescription}
                  onFixTypo={onFixTypo}
                  onChange={(value) => {
                    dispatch({
                      type: 'CHANGE_FORM_VALUE',
                      payload: { key: `educations.items.[${index}].highlights`, value },
                    });
                  }}
                  style={{ minHeight: '200px', outline: 'none', border: 'none' }}
                  initialValue={highlights}
                />
              </SlateEditorWrapper>
            </FullItem>
          </GroupItems>
        ) : null}
      </PaperCollapse>
    </ItemContainer>
  );
};

const commonCities = [
  'Abu Dhabi',
  'Amsterdam',
  'Athens',
  'Auckland',
  'Bangkok',
  'Barcelona',
  'Beijing',
  'Berlin',
  'Bogota',
  'Buenos Aires',
  'Cairo',
  'Cape Town',
  'Caracas',
  'Casablanca',
  'Chicago',
  'Copenhagen',
  'Delhi',
  'Doha',
  'Dubai',
  'Dublin',
  'Edinburgh',
  'Frankfurt',
  'Hanoi',
  'Hong Kong',
  'Honolulu',
  'Istanbul',
  'Jakarta',
  'Johannesburg',
  'Kuala Lumpur',
  'Lima',
  'Lisbon',
  'London',
  'Los Angeles',
  'Madrid',
  'Manila',
  'Marrakech',
  'Melbourne',
  'Mexico City',
  'Montreal',
  'Moscow',
  'Mumbai',
  'Munich',
  'Nairobi',
  'New York',
  'Osaka',
  'Oslo',
  'Paris',
  'Prague',
  'Rio de Janeiro',
  'Riyadh',
  'Rome',
  'San Francisco',
  'Sao Paulo',
  'Seoul',
  'Shanghai',
  'Singapore',
  'Stockholm',
  'Sydney',
  'Tokyo',
  'Toronto',
  'Vancouver',
  'Vienna',
  'Warsaw',
  'Zurich',
];
