import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryResumeArgs } from '../../generated/types';

export const GET_RESUME = gql`
  query resume(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String
    $resumeUuid: String!
    $includeDeleted: Boolean
  ) {
    resume(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      resumeUuid: $resumeUuid
      includeDeleted: $includeDeleted
    ) {
      uuid
      state
      isNew
      isPublic
      isDeleted
      createdAt
      updatedAt
      createdBy {
        uuid
      }
      updatedBy {
        uuid
      }
    }
  }
`;

export const useGetResume = (options?: QueryHookOptions<Pick<Query, 'resume'>, QueryResumeArgs>) =>
  useQuery<Pick<Query, 'resume'>, QueryResumeArgs>(GET_RESUME, options);

export const useLazyGetResume = (options?: QueryHookOptions<Pick<Query, 'resume'>, QueryResumeArgs>) =>
  useLazyQuery<Pick<Query, 'resume'>, QueryResumeArgs>(GET_RESUME, options);
