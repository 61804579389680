import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { ResumeAdditionalSectionsProps } from './ResumeAdditionalSections.types';

export const Container = styled.div<ResumeAdditionalSectionsProps>``;

export const TwoColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  ${media.from_laptop`
    flex-direction: row;
  `}
`;

export const Action = styled.button<{ $isSelected: boolean }>`
  padding: 16px 16px;
  width: calc(50% - 4px);
  border: 1px solid ${Colors.GreyLightest};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  &:hover {
    opacity: 0.8;
  }
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${Colors.GreyLightest};
      cursor: not-allowed;
      &:hover {
        opacity: 1;
      }
    `}
`;

export const ActionLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
