import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationImproveResumeArgs } from '../../generated/types';

export const IMPROVE_RESUME = gql`
  mutation ImproveResume($data: ImproveResumeData!, $userUuid: String, $boardUuid: String, $jobUuid: String) {
    improveResume(data: $data, userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid)
  }
`;

export const useImproveResume = (
  options?: MutationHookOptions<Pick<Mutation, 'improveResume'>, MutationImproveResumeArgs>
) =>
  useMutation<Pick<Mutation, 'improveResume'>, MutationImproveResumeArgs>(IMPROVE_RESUME, {
    ...options,
  });
