import { User } from '@styled-icons/remix-line';
import { User as UserFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function UserIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <User />
    </Icon>
  );
}

export function UserFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <UserFill />
    </Icon>
  );
}
