import { Icon, IconProps } from './Icon';
import { Twitter } from '@styled-icons/boxicons-logos';

export function TwitterIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Twitter />
    </Icon>
  );
}
