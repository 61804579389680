import { ResumeAdditionalSectionsProps } from './ResumeAdditionalSections.types';
import { Action, ActionLabel, Container, TwoColumns } from './ResumeAdditionalSections.styles';
import {
  AddIcon,
  CheckIcon,
  CyclingIcon,
  FaqIcon,
  LinkIcon,
  OfficeBuildingIcon,
  PageBreakIcon,
  PuzzlePieceIcon,
  SchoolIcon,
  SettingsIcon,
  StarIcon,
  SuitcaseIcon,
  TrophyIcon,
} from '../../atoms/Icons';
import { ErrorBoundary } from '../ErrorBoundary';
import generateID from '../../../helper/id';

export const ResumeAdditionalSections = ({
  selectedAdditionalSections,
  onActionClick,
}: ResumeAdditionalSectionsProps) => {
  const allActions = [
    { title: 'Employment History', section: 'employments', Icon: OfficeBuildingIcon },
    { title: 'Internships History', section: 'internships', Icon: SuitcaseIcon },
    { title: 'Education', section: 'educations', Icon: SchoolIcon },
    { title: 'Certifications', section: 'certifications', Icon: TrophyIcon },
    { title: 'Languages', section: 'languages', Icon: StarIcon },
    { title: 'Skills', section: 'skills', Icon: CheckIcon },
    { title: 'Hobbies', section: 'hobbies', Icon: CyclingIcon },
    { title: 'Links', section: 'links', Icon: LinkIcon },
    { title: 'References', section: 'references', Icon: FaqIcon },
    { title: 'Projects', section: 'projects', Icon: PuzzlePieceIcon },
    { title: 'Custom', section: 'custom', Icon: SettingsIcon },
    { title: 'PageBreak', section: 'pagebreak', Icon: PageBreakIcon },
  ];
  const multiSections = ['pagebreak', 'custom'];
  return (
    <ErrorBoundary message="Molecule\ResumeAdditionalSections">
      <Container onActionClick={onActionClick}>
        <TwoColumns>
          {allActions.map(({ section, title, Icon }) => {
            const isSelected = selectedAdditionalSections?.includes(section) && !multiSections.includes(section);
            const key = ['custom', 'pagebreak'].includes(section) ? generateID() : section;
            return (
              <Action
                $isSelected={isSelected}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.preventDefault();
                  if (!isSelected) {
                    onActionClick({
                      key,
                      section,
                      title,
                    });
                  }
                }}
                key={section}
              >
                <ActionLabel>
                  <Icon size={1.5} />
                  {title}
                </ActionLabel>
                <AddIcon size={1.5} />
              </Action>
            );
          })}
        </TwoColumns>
      </Container>
    </ErrorBoundary>
  );
};
