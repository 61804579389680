import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationGenerateHightLightsArgs } from '../../generated/types';

export const GENERATE_HIGH_LIGHTS = gql`
  mutation GenerateHightLights($data: GenerateHightLightsData!, $userUuid: String!, $boardUuid: String!, $jobUuid: String, $resumeUuid: String!) {
    generateHightLights(data: $data, userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, resumeUuid: $resumeUuid)
  }
`;

export const useGenerateHightLights = (
  options?: MutationHookOptions<Pick<Mutation, 'generateHightLights'>, MutationGenerateHightLightsArgs>
) =>
  useMutation<Pick<Mutation, 'generateHightLights'>, MutationGenerateHightLightsArgs>(GENERATE_HIGH_LIGHTS, {
    ...options,
  });
