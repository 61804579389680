import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { useResumeContext } from '../Context';

import { Urls } from '../../urls';
import { useRouter } from '../../../hooks/useRouter';
import { formatUrl } from '../../../utils/url';

import { H3 } from '../../../components/atoms/Typography/Headings';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { Wrapper, WrapperButtons, WrapperLeft, WrapperRight } from './Common.styles';
import { DesignColorPalette } from '../../../components/atoms/DesignColorPalette';
import { ResumePreview } from '../../../components/molecules/ResumePreview';
import { DesignFontSize } from '../../../components/atoms/DesignFontSize';
import { DesignFontFamily } from '../../../components/atoms/DesignFontFamily';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { DesignTemplate } from '../../../components/atoms/DesignTemplate';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { Form } from '../../../components/atoms/Form/Form';
import { PrimaryButton } from '../../../components/atoms/Button';
import { FunctionalIFrameComponent } from '../../../components/atoms/FunctionalIFrameComponent';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { ResumeDesignOptionsForm } from '../../../components/molecules/ResumeDesignOptionsForm';
import { useUserContext } from '../../../contexts/UserContext';
import { ResumeActions } from '../../../components/molecules/ResumeActions';
import { Board, Job } from '../../../generated/types';

export const DesignSection: React.FC<DesignSectionProps> = ({ handleSave, handleDownload, isOpen, board, job }) => {
  const { query: { userUuid, boardUuid, jobUuid, resumeUuid } = {}, navigate, routerState } = useRouter();
  const { state, dispatch, title } = useResumeContext();
  const user = useUserContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!userUuid) {
    return null;
  }

  if (!boardUuid) {
    return null;
  }

  const { isLoading, design = {}, isSaving } = state;
  const { colorPalette, density, fontFamily, templateName } = design as any;

  const child = isLoading ? null : (
    <Wrapper>
      <WrapperLeft>
        <Spacer y={22} />
        <Breadcrumbs>
          <Breadcrumb>
            <Link to={`/${Urls.Boards}`}>All Boards</Link>
          </Breadcrumb>
          <Breadcrumb>
            <Link
              to={formatUrl(`/${Urls.Boards}/${Urls.BoardView}#resumes`, {
                userUuid,
                boardUuid,
              })}
            >
              {board ? board.title : 'Current Board'}
            </Link>
          </Breadcrumb>
          {jobUuid ? (
            <Breadcrumb>
              <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}#resumes`}>
                {job ? job.jobTitle : 'Current Job'}
              </Link>
            </Breadcrumb>
          ) : null}
          <Breadcrumb>Resume</Breadcrumb>
        </Breadcrumbs>
        <H3>Design</H3>
        <InfoAlert>
          <p>
            Let's make it look good! Choose a design that best suits your resume. You can always change it later if you
            change your mind.
          </p>
        </InfoAlert>
        {isLoading ? (
          <SkeletonBlock style={{ height: '260px', marginBottom: '32px' }} />
        ) : (
          <>
            <Form onSubmit={() => {}}>
              <DesignTemplate
                templateName={templateName}
                documentType="resume"
                onActionClick={(templateName) => {
                  dispatch({ type: 'CHANGE_DESIGN_VALUE', payload: { key: 'templateName', value: templateName } });
                }}
              ></DesignTemplate>
              <Spacer y={16} />

              <DesignColorPalette
                colorPalette={colorPalette}
                onActionClick={(colorPalette) => {
                  dispatch({ type: 'CHANGE_DESIGN_VALUE', payload: { key: 'colorPalette', value: colorPalette } });
                }}
              />
              <Spacer y={16} />
              <DesignFontSize
                density={density}
                onActionClick={(density) => {
                  dispatch({ type: 'CHANGE_DESIGN_VALUE', payload: { key: 'density', value: density } });
                }}
              />
              <Spacer y={16} />
              <DesignFontFamily
                fontFamily={fontFamily}
                onActionClick={(fontFamily) => {
                  dispatch({ type: 'CHANGE_DESIGN_VALUE', payload: { key: 'fontFamily', value: fontFamily } });
                }}
              />
              <Spacer y={16} />

              <ResumeDesignOptionsForm design={design} dispatch={dispatch} />
              <Spacer y={16} />

              <WrapperButtons>
                <PrimaryButton
                  onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    await handleSave();
                  }}
                  inline
                  busy={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </PrimaryButton>

                <WrapperButtons>
                  <PrimaryButton
                    onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      if (routerState?.from) {
                        navigate(routerState.from);
                      } else {
                        if (!userUuid || !boardUuid) {
                          navigate(`/${Urls.Dashboard}`);
                        } else {
                          navigate(formatUrl(`/${Urls.Boards}/${Urls.BoardView}`, { userUuid, boardUuid }), {
                            state: routerState,
                          });
                        }
                      }
                    }}
                    inline
                  >
                    Back
                  </PrimaryButton>
                </WrapperButtons>
              </WrapperButtons>
            </Form>
          </>
        )}
      </WrapperLeft>

      <WrapperRight>
        {isLoading ? (
          <SkeletonBlock style={{ height: '660px', marginBottom: '32px' }} />
        ) : (
          <>
            <ResumeActions
              user={user}
              userUuid={userUuid}
              boardUuid={boardUuid}
              jobUuid={jobUuid}
              resumeUuid={resumeUuid}
              handleDownload={handleDownload}
            />
            <FunctionalIFrameComponent title="resume">
              <ResumePreview state={state} isOpen={isOpen} />
            </FunctionalIFrameComponent>
          </>
        )}
      </WrapperRight>
    </Wrapper>
  );

  return (
    <>
      <Helmet title={`${title} - Design`} />
      {child}
    </>
  );
};

type DesignSectionProps = {
  isOpen: boolean;
  job?: Job | null;
  board?: Board | null;
  handleSave: () => Promise<void>;
  handleDownload: () => Promise<void>;
};
