import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateResumeArgs } from '../../generated/types';

export const UPDATE_RESUME = gql`
  mutation UpdateResume(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String
    $resumeUuid: String!
    $state: JSON!
  ) {
    updateResume(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      resumeUuid: $resumeUuid
      state: $state
    ) {
      uuid
      state
      isNew
      isPublic
      isDeleted
      createdAt
      updatedAt
      createdBy {
        uuid
      }
      updatedBy {
        uuid
      }
    }
  }
`;

export const useUpdateResume = (
  options?: MutationHookOptions<Pick<Mutation, 'updateResume'>, MutationUpdateResumeArgs>
) =>
  useMutation<Pick<Mutation, 'updateResume'>, MutationUpdateResumeArgs>(UPDATE_RESUME, {
    awaitRefetchQueries: false,
    refetchQueries: [],
    ...options,
  });
