import { memo, useEffect, useState } from 'react';

import { useRouter } from '../../../hooks/useRouter';
import { Colors } from '../../../styles/colors';

import { ResumeActionsProps } from './ResumeActions.types';
import { Container } from './ResumeActions.styles';
import { PrimaryButton, WrapperButtons } from '../../atoms/Button';
import { CrownIcon, DownloadIcon, LinkIcon } from '../../atoms/Icons';
import { Urls } from '../../../routes';
import { formatUrl } from '../../../utils/url';

const MAX_DOWNLOAD_TIMEOUT = 25000;

const ResumeActionsPure = ({
  userUuid,
  boardUuid,
  jobUuid,
  resumeUuid,
  user,
  job,
  board,
  handleDownload,
}: React.PropsWithChildren<ResumeActionsProps>) => {
  const { navigate } = useRouter();

  const isMember = user?.isMember || false;
  const [isDownloading, setIsDownloading] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isDownloading) {
      timeout = setTimeout(() => {
        setIsDownloading(false);
      }, MAX_DOWNLOAD_TIMEOUT);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isDownloading]);

  return (
    <Container>
      <WrapperButtons>
        <PrimaryButton
          onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            setIsDownloading(true);
            await handleDownload();
            setIsDownloading(false);
          }}
          inline
          busy={isDownloading}
          iconRight={
            isMember ? <DownloadIcon color={Colors.White} size={2} /> : <CrownIcon color={Colors.White} size={1.2} />
          }
        >
          Download
        </PrimaryButton>

        <PrimaryButton
          onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            jobUuid
              ? navigate(
                  `/${Urls.Public}${formatUrl(`/${Urls.PublicJobResumeView}`, {
                    userUuid,
                    boardUuid,
                    jobUuid,
                    resumeUuid,
                  })}`
                )
              : navigate(
                  `/${Urls.Public}${formatUrl(`/${Urls.PublicBoardResumeView}`, { userUuid, boardUuid, resumeUuid })}`
                );
          }}
          inline
          iconRight={<LinkIcon color={Colors.White} size={2} />}
        >
          Share
        </PrimaryButton>
      </WrapperButtons>
    </Container>
  );
};

export const ResumeActions = memo(ResumeActionsPure);
