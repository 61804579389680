import { Github } from '@styled-icons/remix-line';
import { Github as GithubFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function GithubIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Github />
    </Icon>
  );
}

export function GithubFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <GithubFill />
    </Icon>
  );
}
